import { combineReducers } from "redux";
import { reducer as baseReducer } from "./base";

const createRootReducer = (history: any) => {
  return combineReducers({
    base: baseReducer,
  });
};

export default createRootReducer;
