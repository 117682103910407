export const CurrencyFormatter = (
  value: number | string,
  multiplier = 1,
  locales = "en-US",
  currency = "USD",
  maximumFractionDigits = 0
) => {
  const val =
    typeof value === "string" ? Number(value?.replaceAll(",", "")) : value;
  const fullVal = val * multiplier;
  return fullVal && Math.round(fullVal) !== 0
    ? Intl.NumberFormat(locales, {
        style: "currency",
        currency,
        maximumFractionDigits,
      }).format(fullVal)
    : "-";
};

const extractNumbers = (val: string) => {
  const re = /[0-9]+/g;
  const extracted_vals = val.match(re);
  return extracted_vals ? extracted_vals.join("") : "";
};

export const FormatCurrencyNumber = (
  value: string | number,
  locales = "en-US",
  maximumFractionDigits = 0
) => {
  const val = typeof value === "string" ? Number(extractNumbers(value)) : value;
  return new Intl.NumberFormat(locales, {
    maximumFractionDigits,
  }).format(val);
};
