import Modal from "styled-react-modal";
import { Section } from "../layout";
import styled, { css } from "styled-components";
import { IModalProps, ISize, ModalSizes } from "../../@types/modal.types";

const base_modal = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  z-index: 5000;
`;

const getModalWidth = (size: ISize = "lg") => {
  switch (size) {
    case ModalSizes.lg:
      return "80vw";
    case ModalSizes.md:
      return "60vw";
    case ModalSizes.sm:
      return "40vw";
    default:
      return "80vw";
  }
};

const getModalPadding = (size: ISize = "lg") => {
  switch (size) {
    case ModalSizes.lg:
      return "40px";
    case ModalSizes.md:
      return "35px";
    case ModalSizes.sm:
      return "30px";
    default:
      return "35px";
  }
};

export const StyledModal: any = Modal.styled`
  width: ${({ size }: IModalProps) => getModalWidth(size)};
  max-width: 1200px;
  max-height: 80vh;
  z-index: 5000;
  background-color: ${({ backgroundColor }: { backgroundColor: string }) =>
    backgroundColor || "#ffffff"};
  ${base_modal}
`;

export const StyledModalContentWrapper = styled(Section)<IModalProps>`
  padding: ${({ size }) => getModalPadding(size)};
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledCloseButton = styled.div`
  cursor: pointer;
  :hover {
    color: var(--koffie-yellow);
  }
`;
