import { useState } from "react";
import styled, { css } from "styled-components";
import { Section } from "../layout";
import { ITabs, ITab } from "../../@types";

const BaseStyle = css`
  width: 100%;
  height: inherit;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  text-align: center;
  color: var(--primary3);
  border: 2px solid var(--primary3);
  border-right: none;
  font-weight: bold;
  padding: 0 10px;
`;

const StyledTab = styled(Section)`
  ${BaseStyle}
  cursor: pointer;
`;

const StyledActiveTab = styled(Section)`
  ${BaseStyle}
  color: white;
  background-color: var(--primary3);
`;

const ButtonTabs = ({
  tabs,
  activeTab = 0,
  callback,
  style = {},
  styleTab = {},
  styleActiveTab = {},
}: ITabs) => {
  const [currentTab, setCurrentTab] = useState(activeTab);

  const handleTabClick = (key: number, onClick?: any, mappedKey?: string) => {
    setCurrentTab(key);
    if (callback) {
      callback(key, mappedKey);
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <Section
      $flexDirection="row"
      style={{
        width: "auto",
        borderRight: "2px solid var(--primary3)",
        ...style,
      }}
      $justifyContent="space-around"
    >
      {tabs.map((tab: ITab) => {
        const isActive = currentTab === tab.key;
        return isActive ? (
          <StyledActiveTab
            style={{ ...styleTab, ...styleActiveTab }}
            key={tab.key}
          >
            {tab.label}
          </StyledActiveTab>
        ) : (
          <StyledTab
            key={tab.key}
            onClick={() =>
              handleTabClick(tab.key, tab?.onClick, tab?.mappedKey)
            }
            style={styleTab}
          >
            {tab.label}
          </StyledTab>
        );
      })}
    </Section>
  );
};

export default ButtonTabs;
