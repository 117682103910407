export const getFilename = (url: string) => {
  const idxQueryString = url?.indexOf("?");
  const urlSubstring = url?.substring(0, idxQueryString);
  return urlSubstring?.split("/").pop();
};

export const getFileSizeVerbose = (size: number) => {
  if (size >= 1024000) {
    return `${size / 1024000}mb`;
  }
  if (size < 1024000) {
    return `${size / 1000}kb`;
  }
};
