import styled from "styled-components";
import { LoadingSpinner } from "../loading-spinner";

const StyledButton: any = styled.button<any>`
  display: inline-flex;
  height: 50px;
  width: 160px;
  border: 2px solid
    ${({
      $btn_type,
      disabled,
    }: {
      $btn_type: ButtonTypes;
      disabled: boolean;
    }) =>
      disabled
        ? "var(--inactive-grey)"
        : $btn_type === "primary"
        ? "var(--primary1)"
        : "var(--primary3)"};
  color: ${({
    $btn_type,
    disabled,
  }: {
    $btn_type: ButtonTypes;
    disabled: boolean;
  }) =>
    disabled
      ? "var(--inactive-grey)"
      : $btn_type === "primary"
      ? "var(--primary1)"
      : "var(--primary3)"};
  background-color: ${({ $btn_type }: { $btn_type: ButtonTypes }) =>
    $btn_type === "alternative" ? "transparent" : "#ffffff"};
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  font-size: 0.8em;
  letter-spacing: 1.5px;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  cursor: ${({ disabled }: { disabled: boolean }) =>
    disabled ? "not-allowed" : "pointer"};
  span {
    position: relative;
    transition: all 0.35s ease-out;
  }
  &:hover span {
    color: #ffffff;
  }
  &:hover .button-slide {
    left: 0;
  }
`;

const StyledSlide = styled.div<any>`
  width: 100%;
  height: 100%;
  left: -200px;
  background: ${({
    $btn_type,
    disabled,
  }: {
    $btn_type: ButtonTypes;
    disabled: boolean;
  }) =>
    disabled
      ? "var(--inactive-grey)"
      : $btn_type === "primary"
      ? "var(--primary1)"
      : "var(--primary3)"};
  position: absolute;
  transition: all 0.35s ease-Out;
  bottom: 0;
`;

type ButtonTypes = "primary" | "secondary" | "alternative";

interface IButtonProps {
  label: string;
  /*
   * onClick needs to stay optional because
   * for buttons in forms, the onClick f(x)
   * is handled at form submit level
   * */
  onClick?: (data?: any) => void;
  btn_type: ButtonTypes;
  style?: object;
  type?: string;
  loading?: boolean;
  isDisabled?: boolean;
}

export const StyledButtonHoverSlide = (props: IButtonProps) => {
  const {
    label,
    onClick,
    btn_type = "primary",
    loading,
    style = {},
    isDisabled,
  } = props || {};
  return !loading ? (
    <StyledButton
      onClick={onClick}
      $btn_type={btn_type}
      style={style}
      disabled={isDisabled}
    >
      <StyledSlide
        className="button-slide"
        $btn_type={btn_type}
        disabled={isDisabled}
      />
      <span>{label}</span>
    </StyledButton>
  ) : (
    <StyledButton $btn_type={btn_type} disabled>
      <LoadingSpinner
        size="xs"
        color={`var(--primary${btn_type === "primary" ? 1 : 3})`}
      />
    </StyledButton>
  );
};
