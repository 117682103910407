import styled, { css } from "styled-components";
import Section from "./section";
import { devices } from "../../utils";
import LinearProgress from "@mui/material/LinearProgress";

export { default as Section } from "./section";

export const SectionLayoutCss = css`
  width: 100%;
  @media ${devices.mobileS} {
    max-width: 98vw;
    padding: 3% 2%;
  }
  @media ${devices.tablet} {
    max-width: 96vw;
    padding: 4% 3%;
  }
  @media ${devices.laptop} {
    max-width: 1500px;
    padding: 5% 4%;
  }
`;

export const SectionLayout = styled(Section)`
  padding: 6% 5%;
  ${SectionLayoutCss}
`;

export const CoverLayout = styled(Section)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  width: 100vw;
  min-height: 100vh;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const FullLayout = styled(Section)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  width: inherit;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const BoxPadding = styled(Section)`
  padding: 2em 3em;
`;

export const Card: any = styled(Section)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ $noCardBg }) => ($noCardBg ? "1.5em 0 0 0" : "1.5em 0 3em")};
  margin: 1.5em 0;
  width: 100%;
  height: 100%;
  background-color: ${({ $noCardBg }) => ($noCardBg ? "none" : "white")};
  box-shadow: ${({ $noCardBg }) =>
    $noCardBg ? "none" : "var(--base-box-shadow)"};
`;

export const CardInner = styled(Section)`
  height: 100%;
  width: inherit;
`;

export const StyledCardWrapper = styled(Section)`
  margin: 0 1em 1em 0;
  height: 100%;
`;

export const StyledCardHeader = styled(Section)`
  border: 1px solid
    ${({ active }) => (active ? "var(--primary1)" : "var(--primary3)")};
  background-color: ${({ active }) =>
    active ? "var(--primary1)" : "var(--primary3)"};
  color: white;
  font-size: 1.1em;
  font-weight: bold;
  padding: 10px 20px;
`;

export const StyledCardBody = styled(Section)`
  border: 1px solid
    ${({ active }) => (active ? "var(--primary1)" : "var(--primary3)")};
  color: var(--koffie-grey);
  padding: 10px 20px 20px 20px;
  text-align: left;
  height: inherit;
`;

export const LinearProgressBar = ({ style, loading }: any) => (
  <Section style={style}>{loading && <LinearProgress />}</Section>
);

// export const StyledFormSection = styled(Section)`
//   background-color: #ffffff;
//   border-radius: 8px;
//   box-shadow: var(--base-box-shadow);
//   flex-direction: column;
//   height: min-content;
//   margin-bottom: var(--base-margin);
//   @media ${devices.mobileS} {
//     padding: 2% 3%;
//   }
//   @media ${devices.tablet} {
//     padding: 3% 5%;
//   }
//   @media ${devices.laptop} {
//     padding: 3.5% 5.5%;
//   }
//   @media ${devices.laptopL} {
//     padding: 4% 5.5%;
//   }
// `;

interface IImgBox {
  src: string;
  width?: number | string;
  height?: number | string;
  alt: string;
  style?: object;
  onClick?: () => void;
}

export const ImgBox = ({
  src,
  width,
  height,
  alt,
  style,
  onClick,
}: IImgBox) => {
  return (
    <Section
      style={{
        padding: "20px",
        cursor: !onClick ? "auto" : "pointer",
        ...style,
      }}
      onClick={onClick}
      $alignItems="center"
      $justifyContent="center"
    >
      <img src={src} alt={alt} width={width} height={height} />
    </Section>
  );
};
