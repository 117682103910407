import styled from "styled-components";
import {
  space,
  width,
  height,
  flex,
  shadow,
} from "styled-system";

const Section: any = styled.div<any>`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  text-align: left;
  ${space};
  ${width};
  ${height};
  flex-direction: ${({ $flexDirection }) => $flexDirection};
  justify-content: ${({ $justifyContent }) => $justifyContent};
  align-items: ${({ $alignItems }) => $alignItems};
  ${flex};
  flex-wrap: ${({ $flexWrap }) => $flexWrap};
  ${shadow};
`;

export default Section;
