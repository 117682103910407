import { create } from "apisauce";
// import qs from "query-string";

const api = create({
  baseURL: `${
    process.env.NODE_ENV === "development"
      ? "http://localhost:8080"
      : window.location.origin
  }/api`,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const multipartHeaders = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export default api;
