import React from "react";
import { useState } from "react";
import {
  StyledModal,
  StyledCloseButton,
  StyledModalContentWrapper,
} from "./styled_modal_components";
import { getMuiIcons } from "../../utils/@mui-icons";
import { IModalProps, ModalSizes } from "../../@types/modal.types";
import { isEmpty } from "lodash";

const ModalComponent = ({
  Trigger,
  title,
  children,
  size = ModalSizes.lg,
  onClose = undefined,
  open = false,
}: IModalProps) => {
  const [isOpen, setIsOpen] = useState(open);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleCloseModal = () => {
    if (onClose) {
      onClose();
    }
    toggleModal();
  };

  const ChildrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      // @ts-ignore
      return React.cloneElement(child, { $toggleModal: toggleModal });
    }
    return child;
  });

  return (
    <>
      {Trigger && <Trigger onClick={toggleModal} />}
      <StyledModal isOpen={isOpen} onEscapeKeydown={toggleModal} size={size}>
        {!isEmpty(title) && (
          <StyledModalContentWrapper
            $flexDirection="row"
            $justifyContent="space-between"
            $alignItems="flex-start"
            style={{
              backgroundColor: "var(--primary3)",
              color: "white",
            }}
          >
            <h2 style={{ margin: 0 }}>{title}</h2>
            <StyledCloseButton onClick={handleCloseModal}>
              {getMuiIcons("Close")}
            </StyledCloseButton>
          </StyledModalContentWrapper>
        )}
        <StyledModalContentWrapper
          $justifyContent="flex-start"
          style={{ overflowY: "auto" }}
        >
          {ChildrenWithProps}
        </StyledModalContentWrapper>
      </StyledModal>
    </>
  );
};

export default ModalComponent;
