import { Section } from "../../layout";
import {
  StyledFormWarnings,
  StyledInputField,
  StyledFieldWrapper,
  StyledTextarea,
} from "../styled-form-components";
import { ErrorsHandler } from "../errors.handler";
import { LoadingSpinner } from "../../loading-spinner";
import React, { useMemo, useEffect, useCallback, useState } from "react";
import { debounce, isEmpty } from "lodash";
import { FieldLabelComponent } from "../";
import { StyledButton } from "../../buttons";

export const InputSearchField = (props: any) => {
  const {
    label,
    name,
    errors,
    touchedFields,
    hint,
    info,
    loading,
    // searchAction,
    register,
    onSearchClick,
    searchSucceeded,
    watch,
    rules,
    // handleAutosave,
    setValue,
    // trigger,
    getFieldState,
    hasInitialValue,
    required = false,
    validateOnEvent,
    renderTextarea,
    // ...restOfProps
  } = props;

  useEffect(() => {
    if (!searchSucceeded) {
      setValue(name, "", {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchSucceeded]);

  const fieldError = errors[name];
  const value = watch(name);
  const fieldTouched = touchedFields[name] || !isEmpty(value);
  const [lastSearchedValue, setLastSearchedValue] = useState("");

  const handleInputChange = (ev: any) => {
    const value = ev.target.value || "";
    setValue(name, value, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const handleSearchInput = useCallback(() => {
    const { error } = getFieldState(name);
    if (
      error === undefined &&
      !isEmpty(value) &&
      !searchSucceeded &&
      value !== lastSearchedValue
    ) {
      if (!hasInitialValue) {
        setLastSearchedValue(value);
        onSearchClick(value, validateOnEvent);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, searchSucceeded, lastSearchedValue, hasInitialValue]);

  const handleDirectSearch = (value: string) => {
    setLastSearchedValue(value);
    onSearchClick(value);
  };

  const debouncedSearchInput = useMemo(
    () => debounce(handleSearchInput, 300),
    [handleSearchInput]
  );

  const handleKeyUp = (ev: any) => {
    if (ev.key === "Enter" && !showError) {
      handleDirectSearch(value);
    }
  };

  useEffect(() => {
    if (!fieldError && !isEmpty(value) && value?.length === 8) {
      debouncedSearchInput();
    }
  }, [fieldError, debouncedSearchInput, value]);

  const showError = fieldError && fieldTouched;
  const showWarnings =
    fieldError?.type === "digits"
      ? showError
      : isEmpty(lastSearchedValue)
      ? showError
      : showError && watch(name) === lastSearchedValue;

  return (
    <StyledFieldWrapper $flexDirection="column" $alignItems="flex-start">
      <FieldLabelComponent
        label={label}
        info={info}
        hint={hint}
        isRequired={required}
      />
      <Section $flexDirection="row" $alignItems="center">
        {renderTextarea ? (
          <StyledTextarea
            name={name}
            label={label}
            {...register(name, rules)}
            onChange={handleInputChange}
            disabled={loading}
          />
        ) : (
          <StyledInputField
            role="input"
            type="text"
            name={name}
            label={label}
            {...register(name, rules)}
            onChange={handleInputChange}
            onKeyUp={handleKeyUp}
            disabled={loading}
          />
        )}
        {loading ? (
          <div style={{ marginLeft: "10px" }}>
            <LoadingSpinner />
          </div>
        ) : (
          <Section
            style={{
              color: "var(--primary1)",
              marginLeft: "10px",
              width: "130px",
              cursor: "pointer",
            }}
            $justifyContent="center"
            $alignItems="center"
            $flexDirection="row"
            onClick={() => handleDirectSearch(watch(name))}
          >
            {hasInitialValue ? (
              <StyledButton
                style={{ height: "38px", marginTop: "4px", width: "130px" }}
                label="Refresh"
                btn_type="primary"
              />
            ) : (
              <StyledButton
                style={{ height: "38px", marginTop: "4px", width: "130px" }}
                label="Search"
                btn_type="primary"
              />
            )}
          </Section>
        )}
      </Section>
      {!loading &&
        searchSucceeded === false &&
        fieldError === undefined &&
        watch(name) === lastSearchedValue &&
        !isEmpty(watch(name)) && (
          <StyledFormWarnings>
            {ErrorsHandler(
              {
                message: `- ${lastSearchedValue} not found, please try again.`,
              },
              label
            )}
          </StyledFormWarnings>
        )}
      {!loading && showWarnings && (
        <StyledFormWarnings>
          {ErrorsHandler(fieldError, label)}
        </StyledFormWarnings>
      )}
    </StyledFieldWrapper>
  );
};
