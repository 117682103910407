export type ISize = "lg" | "md" | "sm";

export interface IModalProps {
  Trigger?: any;
  title?: string;
  children?: any;
  size?: ISize;
  onClick?: (props: any) => void;
  onClose?: () => void;
  open?: boolean;
}

export const ModalSizes = {
  lg: "lg" as ISize,
  md: "md" as ISize,
  sm: "sm" as ISize,
};
