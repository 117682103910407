import {
  Section,
  Dropzone,
  getMuiIcons,
  IconTypes,
  FieldLabelComponent,
  StyledFileTags,
} from "../../../../";
import React, { useCallback, useState } from "react";
import { isEmpty } from "lodash";

const DropzoneComponent = (props: any) => {
  const {
    files = [],
    acceptedFileExt,
    field,
    successCallback,
    handleFileRemove,
    uploadCall,
    validationCall,
    validationCallback,
    label,
    mappedField,
    multiple = true,
    formData,
  } = props || {};
  const { hint } = field || {};

  const uploadedFiles = !isEmpty(files) ? files : formData?.[mappedField] || [];

  const successUploadCallback = useCallback((storageURL: string) => {
    if (successCallback) {
      successCallback(storageURL, mappedField);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const successValidationCallback = useCallback((data: any) => {
    if (validationCallback) {
      validationCallback(data, mappedField);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilenameFromStorageURL = (storageURL: string) => {
    // eslint-disable-next-line
    const [url, query] = storageURL?.split("?");
    const filenameSerialized = url?.split("/").pop();
    return filenameSerialized?.substring(37);
  };

  const formattedUploadedFiles = uploadedFiles?.map((storageURL: string) => {
    return {
      url: storageURL,
      filename: getFilenameFromStorageURL(storageURL),
    };
  });

  const imageExts = [];
  if (acceptedFileExt?.includes(".jpeg")) {
    imageExts.push(".jpeg");
  }
  if (acceptedFileExt?.includes(".png")) {
    imageExts.push(".png");
  }

  const acceptedFileTypes = {
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      acceptedFileExt?.includes(".docx") ? [".docx"] : [],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      acceptedFileExt?.includes(".xlsx") ? [".xlsx"] : [],
    "application/pdf": acceptedFileExt?.includes(".pdf") ? [".pdf"] : [],
    "image/*": imageExts,
  };

  return (
    <Section $flexDirection="column">
      <FieldLabelComponent label={label} hint={hint} isRequired={false} />
      <Section>
        {!isEmpty(formattedUploadedFiles) &&
          formattedUploadedFiles.map((file: any, idx: number) => {
            return (
              <StyledFileTags key={idx}>
                <div>
                  <span style={{ marginRight: "5px", fontSize: "15px" }}>
                    {getMuiIcons(IconTypes.attachFile)}
                  </span>
                  <span>{file?.filename}</span>
                </div>
                <span
                  style={{ cursor: "pointer", margin: "2px 0 0 10px" }}
                  onClick={() => handleFileRemove(file?.url, mappedField)}
                >
                  {getMuiIcons(IconTypes.clear)}
                </span>
              </StyledFileTags>
            );
          })}
      </Section>
      <Dropzone
        successCallback={successUploadCallback}
        acceptedFileTypes={acceptedFileTypes}
        maxFileSize={5120000}
        uploadCall={uploadCall}
        validationCall={validationCall}
        successValidationCallback={successValidationCallback}
        multiple={multiple}
      />
    </Section>
  );
};

export default DropzoneComponent;
