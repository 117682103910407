import { useState } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import Tooltip from "@mui/material/Tooltip";
import { Section } from "../layout";
import { getMuiIcons, IconTypes } from "../../utils/@mui-icons";
import { animFadeIn } from "../../animation";
import { isEmpty } from "lodash";

interface ICompProps {
  open: boolean;
}

const StyledSidebar = styled(Section)`
  height: 100vh;
  background-color: var(--koffie-primary);
  box-shadow: var(--base-box-shadow);
  color: #ffffff;
  cursor: pointer;
  flex-direction: row;
  transition: width 200ms;
`;

const StyledCollapsible = styled(Section)<ICompProps>`
  width: ${({ open }) => `${open ? 265 : 5}px`};
  transition: width 300ms;
`;

const baseLinkCss = css`
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.2em;
  justify-content: center;
  align-items: center;
`;

const StyledLink = styled(Link)`
  ${baseLinkCss}
`;

const StyledDivLink = styled.div`
  ${baseLinkCss}
`;

const StyledFadeInLink = styled(StyledLink)`
  ${animFadeIn}
`;

const StyledFadeInDivLink = styled(StyledDivLink)`
  ${animFadeIn}
`;

export interface ISidebarNavItem {
  icon: string;
  label: string;
  path?: string;
  url?: string;
  isDisabled?: boolean;
}

const getLinkStyles = (isDisabled: boolean) => ({
  cursor: isDisabled ? "not-allowed" : "pointer",
  color: isDisabled ? "var(--koffie-primary-blue)" : "#ffffff",
});

const SidebarLink = ({
  isIconLink,
  isExternalUrl,
  to,
  onClick,
  isDisabled,
  children,
}: any) => {
  const linkStyles = getLinkStyles(isDisabled);
  return isIconLink ? (
    isExternalUrl ? (
      <StyledDivLink onClick={onClick} style={linkStyles}>
        {children}
      </StyledDivLink>
    ) : (
      <StyledLink to={isDisabled ? "#" : to} style={linkStyles}>
        {children}
      </StyledLink>
    )
  ) : isExternalUrl ? (
    <StyledFadeInDivLink onClick={onClick} style={linkStyles}>
      {children}
    </StyledFadeInDivLink>
  ) : (
    <StyledFadeInLink to={isDisabled ? "#" : to} style={linkStyles}>
      {children}
    </StyledFadeInLink>
  );
};

export const CollapsibleSidebar = ({
  navItems,
  logout,
}: {
  navItems: Array<ISidebarNavItem>;
  logout?: () => void;
}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    const host = window?.location?.host;
    if (logout) {
      logout();
    }
    window.location.replace(
      `${window.location.href}?gcp-iap-mode=GCIP_SIGNOUT`
    );
  };

  const handleExternalUrl = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <Section $flexDirection="row" style={{ width: "auto" }}>
      <StyledSidebar>
        <Section
          style={{ padding: "30px 10px 30px 15px", width: "55px" }}
          $alignItems="center"
          $justifyContent="space-between"
          onClick={handleClick}
        >
          <div style={{ marginTop: "8px" }}>
            {navItems.map((navItem: ISidebarNavItem, idx: number) => {
              const { icon, path = "/", url = "", label, isDisabled } = navItem;
              const isExternalUrl = !isEmpty(url);
              return (
                <SidebarLink
                  key={idx}
                  isIconLink={true}
                  isExternalUrl={isExternalUrl}
                  onClick={() => handleExternalUrl(url)}
                  isDisabled={isDisabled}
                  to={path}
                >
                  <Tooltip title={label} placement="right">
                    <Section
                      style={{ margin: "0 0 24px" }}
                      $flexDirection="row"
                    >
                      {getMuiIcons(icon)}
                    </Section>
                  </Tooltip>
                </SidebarLink>
              );
            })}
          </div>
          <StyledDivLink onClick={handleLogout}>
            <Tooltip title="Logout" placement="right">
              <Section style={{ margin: "4px 0 20px" }} $flexDirection="row">
                {getMuiIcons(IconTypes.logout)}
              </Section>
            </Tooltip>
          </StyledDivLink>
        </Section>
        <StyledCollapsible
          open={open}
          style={{ padding: "30px 0" }}
          $justifyContent="space-between"
        >
          <div>
            {open &&
              navItems.map((navItem: ISidebarNavItem, idx: number) => {
                const { path = "", url = "", label, isDisabled } = navItem;
                const isExternalUrl = !isEmpty(url);
                return (
                  <SidebarLink
                    key={idx}
                    isIconLink={false}
                    isExternalUrl={isExternalUrl}
                    onClick={() => handleExternalUrl(url)}
                    isDisabled={isDisabled}
                    to={path}
                  >
                    <Section
                      style={{ margin: "5px 0 20px" }}
                      $flexDirection="row"
                    >
                      <span
                        style={{
                          marginLeft: "10px",
                        }}
                      >
                        {label}
                      </span>
                    </Section>
                  </SidebarLink>
                );
              })}
          </div>
          {open && (
            <StyledFadeInDivLink onClick={handleLogout}>
              <Section style={{ margin: "5px 0 20px" }} $flexDirection="row">
                <span
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  Logout
                </span>
              </Section>
            </StyledFadeInDivLink>
          )}
        </StyledCollapsible>
      </StyledSidebar>
    </Section>
  );
};
