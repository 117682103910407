import { isEmpty } from "lodash";

export const ErrorsHandler = (error: any, fieldLabel: string) => {
  const { message, type } = error;
  if (message) {
    const msgComps = message?.split(" ");
    msgComps?.shift();
    return "* This " + msgComps?.join(" ");
  }
  return "Must be a valid selection / value";
};
