import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridAlignment,
  DataGridProps,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { CardInner } from "../layout";
import LinearProgress from "@mui/material/LinearProgress";
import styled from "styled-components";

export const StyledCustomCellWrapper = styled.div`
  color: var(--koffie-grey);
  padding-top: 6px;
`;

interface IDataGridProps extends Partial<DataGridProps> {
  title?: string;
  hideToolbar?: boolean;
  toolbarSettings?: IToolbarSettings;
  noCardBg?: boolean;
  rows: GridRowsProp;
  columnHeaders: Array<GridColDef>;
  getRowClassName?: (params: any) => string;
  handleCellEditCommit?: (params: any) => void;
  defaultPageSize?: number;
  pageSizeOptions?: Array<number>;
  density?: any;
  hideFooter?: boolean;
  loading?: boolean;
  columns?: any;
  sx?: any;
}

export const GridAlignmentOptions = {
  left: "left" as GridAlignment,
  center: "center" as GridAlignment,
  right: "right" as GridAlignment,
};

const border = "1px solid var(--background-darker-grey)";

interface IToolbarSettings {
  columnsBtn?: boolean;
  filterBtn?: boolean;
  densityBtn?: boolean;
  exportBtn?: boolean;
  color?: string;
  csvOptions?: ICSVOptions;
}

interface ICSVOptions {
  allColumns?: boolean; // if true, the hidden columns will also be exported.
  delimiter?: string; // default is ',' to separate fields.
  fields?: Array<string>; // columns to be exported - this should only be used to restrict columns exports
  fileName?: string;
  includeHeaders: boolean; // if true, first row of the csv will include the headers.
  utf8WithBom?: boolean; // if true, the UTF-8 Byte Order Mark (BOM) prefixes the exported file. This can allow Excel to automatically detect file encoding as UTF-8.
}

const CustomToolbar = (settings: IToolbarSettings) => {
  const defaultSettings = {
    columnsBtn: true,
    filterBtn: true,
    densityBtn: true,
    exportBtn: false,
    color: "black",
  };
  const toolbarSettings = {
    ...defaultSettings,
    ...settings,
  };

  const {
    columnsBtn,
    filterBtn,
    densityBtn,
    exportBtn,
    color,
    csvOptions,
  }: IToolbarSettings = toolbarSettings;

  return (
    <GridToolbarContainer style={{ color }}>
      {columnsBtn && (
        <GridToolbarColumnsButton
          slotProps={{
            button: { color: "inherit" },
            tooltip: { color: "inherit " },
          }}
        />
      )}
      {filterBtn && (
        <GridToolbarFilterButton
          slotProps={{
            button: { color: "inherit" },
            tooltip: { color: "inherit " },
          }}
        />
      )}
      {densityBtn && (
        <GridToolbarDensitySelector
          slotProps={{
            button: { color: "inherit" },
            tooltip: { color: "inherit " },
          }}
        />
      )}
      {exportBtn && (
        <GridToolbarExport color="inherit" csvOptions={csvOptions} />
      )}
    </GridToolbarContainer>
  );
};

/*
 * @2022-06-29
 * TODO: Check-in on @MUI Datagrid updates.
 * Cell and column value copy to clipboard is supposedly on the roadmap for Q3 2022.
 * Prior to this update, implement copy to clipboard on only necessary cells.
 */
const DataGridComponent = ({
  title,
  rows,
  columnHeaders,
  sx,
  columns,
  loading,
  noCardBg = false,
  hideFooter,
  hideToolbar = false,
  toolbarSettings = {},
  density = "standard",
  defaultPageSize = 25,
  pageSizeOptions = [10, 25, 50, 100],
  ...otherProps
}: IDataGridProps) => {
  return (
    <>
      {title && <h3>{title.toUpperCase()}</h3>}
      <CardInner>
        <DataGrid
          rows={rows}
          columns={columnHeaders}
          loading={loading}
          density={density}
          // @ts-ignore
          slots={
            hideToolbar
              ? {
                  loadingOverlay: LinearProgress,
                }
              : {
                  toolbar: () => CustomToolbar(toolbarSettings),
                  loadingOverlay: LinearProgress,
                }
          }
          hideFooter={hideFooter}
          sx={{
            borderRadius: 0,
            width: "inherit",
            maxWidth: "inherit",
            "& .MuiLinearProgress-root": {
              backgroundColor: "#FFA100",
              height: "2px",
            },
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#FFD600",
              height: "2px",
            },
            "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
            "& .MuiDataGrid-columnHeaders": {
              borderTop: border,
              borderBottom: border,
              backgroundColor: "var(--background-darker-grey)",
              textAlign: "center",
              borderRadius: 0,
            },
            "& .MuiDataGrid-row": {
              "--rowBorderColor": "none",
              display: "flex",
              alignItems: "center",
              ":hover": {
                backgroundColor: "rgba(142, 193, 227, 0.45)",
                fontWeight: "bold",
              },
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: border,
            },
            "& .MuiDataGrid-cell": {
              borderBottom: border,
            },
            border: "none",
            borderBottom: hideFooter ? border : "none",
            boxSizing: "border-box",
            "--DataGrid-rowBorderColor": "none",
            ...sx,
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: defaultPageSize } },
          }}
          pageSizeOptions={pageSizeOptions}
          {...otherProps}
        />
      </CardInner>
    </>
  );
};

export default DataGridComponent;
