import styled from "styled-components";
import { topBarHeight } from "../../utils/constants";
import { Section, SectionLayoutCss } from "../layout";
// import Logo from "../../assets/wholesure_logo_horiz.jpeg";
import Avatar from "@mui/material/Avatar";

const StyledTopBar = styled(Section)`
  width: 100%;
  height: ${topBarHeight}px;
  box-shadow: var(--base-box-shadow);
  background-color: #ffffff;
  padding: 0 20px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const StyledContentWrapper = styled(Section)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1500px;
  ${SectionLayoutCss}
`;

export const TopBar = ({ currentUser }: any) => {
  let updated_user = currentUser;
  if (currentUser?.includes(":")) {
    const [prefix, email] = currentUser?.split(":");
    updated_user = email;
  }
  return (
    <StyledTopBar>
      <StyledContentWrapper>
        <Section
          $alignItems="flex-start"
          onClick={() => (window.location.href = window.location.origin)}
          style={{ cursor: "pointer", paddingTop: "4px" }}
        >
          <img
            src="https://storage.googleapis.com/wls-assets/logos/wtu_black.png"
            alt="wholesure"
            height="70"
            width="auto"
          />
        </Section>
        <Section
          $justifyContent="flex-end"
          $alignItems="center"
          $flexDirection="row"
        >
          <Avatar sx={{ width: 28, height: 28 }} />
          <span style={{ marginLeft: "10px" }}>{updated_user}</span>
        </Section>
      </StyledContentWrapper>
    </StyledTopBar>
  );
};
