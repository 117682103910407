import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { Section } from "../layout";
import { useCallback, useState } from "react";
import useAsyncQueue from "use-async-queue";
import { LoadingSpinner } from "../loading-spinner";
import { getFileSizeVerbose } from "./util";
import { isEmpty } from "ramda";

const StyledZoneWrapper = styled(Section)`
  padding: 5px 0 10px;
`;

const StyledZone = styled(Section)`
  border: ${({ $isDragActive, $multiple }) =>
    !$multiple
      ? "none"
      : $isDragActive
      ? "1px dashed var(--primary1)"
      : "1px dashed var(primary3)"};
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const DropZone = ({
  validationCall,
  uploadCall,
  successCallback,
  successValidationCallback,
  acceptedFileTypes = [],
  maxFileSize = 2048000,
  multiple = true,
}: any) => {
  // eslint-disable-next-line
  const [cache, setCache] = useState({});
  const inflight = useCallback(
    ({ id }: { id: string }) => {
      setCache((c) => {
        return { ...c, [id]: "inflight" };
      });
    },
    [setCache]
  );
  const done = useCallback(
    ({ id, result }: any) => {
      result
        .then((res: any) => {
          return res?.[0]?.data;
        })
        .then((body: any) => {
          if (successCallback) {
            successCallback(body);
          }
          if (successValidationCallback) {
            successValidationCallback(body);
          }
          setCache((c) => ({
            ...c,
            [id]: body,
          }));
        });
    },
    [setCache, successCallback, successValidationCallback]
  );
  const queue = useAsyncQueue({
    concurrency: 2,
    inflight,
    done,
  });

  const wrappedCall = (filename: string, fileToUpload: File, call: any) => {
    const uploadFormData = new FormData();
    const blob = new Blob([fileToUpload], {
      type: fileToUpload.type,
    });

    uploadFormData.append("content_type", fileToUpload?.type);
    uploadFormData.append("file", blob, filename);
    uploadFormData.append("filename", filename);

    return call(uploadFormData);
  };

  const addFileToUploadQueue = useCallback(
    (file_name: string, fileToUpload: File) => {
      const qTask = {
        id: file_name,
        task: () => {
          let promises = [];
          if (validationCall) {
            promises.push(
              new Promise((resolve) => {
                setTimeout(
                  () =>
                    resolve(
                      wrappedCall(file_name, fileToUpload, validationCall)
                    ),
                  1000
                );
              })
            );
          }
          if (uploadCall) {
            promises.push(
              new Promise((resolve) => {
                setTimeout(
                  () =>
                    resolve(wrappedCall(file_name, fileToUpload, uploadCall)),
                  1000
                );
              })
            );
          }

          return Promise.all(promises);
        },
      };
      queue.add(qTask);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onDrop = useCallback(
    (acceptedFiles: Array<File>) => {
      acceptedFiles.forEach((file: File) => {
        addFileToUploadQueue(file.name, file);
      });
    },
    [addFileToUploadQueue]
  );

  const fileSizeValidator = (file: File) => {
    if (file.size > maxFileSize) {
      return {
        code: "file-too-large",
        message: `File is larger than ${getFileSizeVerbose(maxFileSize)}`,
      };
    }

    return null;
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    // acceptedFiles,
    fileRejections,
  } = useDropzone({
    onDrop,
    validator: fileSizeValidator,
    accept: acceptedFileTypes,
    multiple,
  });

  const { numInFlight, numPending } = queue.stats; // numDone
  const uploadInProgress = numInFlight > 0 || numPending > 0;

  return (
    <StyledZoneWrapper>
      {fileRejections && !isEmpty(fileRejections) && (
        <Section style={{ margin: "10px 0" }}>
          <span>
            <b>{`${
              fileRejections.length === 1 ? "File" : "Files"
            } not accepted:`}</b>
            <Section>
              {fileRejections.map((e, idx) => {
                const { file, errors } = e;
                return (
                  <Section
                    key={idx}
                    style={{ fontSize: "0.9em", marginBottom: "8px" }}
                  >
                    <span>{file?.name}</span>
                    <span style={{ color: "red" }}>
                      {errors.map((err) => err.message).join(",")}
                    </span>
                  </Section>
                );
              })}
            </Section>
          </span>
        </Section>
      )}
      <StyledZone
        $isDragActive={isDragActive}
        $multiple={multiple}
        $disable={uploadInProgress ? uploadInProgress : undefined}
      >
        <Section
          {...getRootProps()}
          style={{
            padding: "2em",
            backgroundColor:
              isDragActive || !multiple
                ? "var(--primary2)"
                : "rgba(34, 31, 32, 0.1)",
            color: isDragActive || !multiple ? "white" : "black",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          <input {...getInputProps()} />
          {uploadInProgress
            ? "File upload in progress, please wait."
            : multiple
            ? "Drop files here, or click to select files"
            : "Select file to upload"}
          {uploadInProgress && (
            <Section style={{ marginTop: "20px" }} $alignItems="center">
              <LoadingSpinner />
              <span
                style={{ marginTop: "20px" }}
              >{`In progress: ${numInFlight} file${
                numInFlight > 1 ? "s" : ""
              } uploading.`}</span>
              {numPending > 0 && <span>{`${numPending} files queued.`}</span>}
            </Section>
          )}
        </Section>
      </StyledZone>
    </StyledZoneWrapper>
  );
};

export default DropZone;
