import React from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import { Section } from "../layout";
import MuiAlert from "@mui/material/Alert";
import { isEmpty } from "lodash";

export type IAlertSeverity = "error" | "warning" | "info" | "success";

export const AlertSeverity = {
  error: "error" as IAlertSeverity,
  warning: "warning" as IAlertSeverity,
  info: "info" as IAlertSeverity,
  success: "success" as IAlertSeverity,
};

export interface IAlertLink {
  label: string;
  href: string;
}

export interface IAlertRenderer {
  id?: string;
  severity: IAlertSeverity;
  title?: string;
  message: string;
  onClose?: () => void;
  open?: boolean;
  links?: Array<IAlertLink>;
  style?: any;
  msgStyle?: any;
}

const StyledAlertMessage = styled.div`
  white-space: pre-line;
`;

const MessageBody = ({
  parsedMessage,
  links,
  style = {},
}: {
  parsedMessage: string;
  links: Array<IAlertLink>;
  style?: any;
}) => {
  return (
    <StyledAlertMessage>
      <Section $alignItems="center" style={style}>
        <span>{parsedMessage}</span>
        <Section style={{ marginTop: links && !isEmpty(links) ? "10px" : 0 }}>
          {links?.map((link: IAlertLink, idx) => {
            const { label, href } = link;
            return (
              <a key={idx} href={href}>
                {label}
              </a>
            );
          })}
        </Section>
      </Section>
    </StyledAlertMessage>
  );
};

const AlertRenderer = ({
  open,
  onClose,
  title,
  message,
  severity,
  links = [],
  style = {},
  msgStyle = {},
}: IAlertRenderer) => {
  const parsedMessage = message?.replaceAll("\\n", "\n");
  return onClose ? (
    <Section style={style}>
      <Stack>
        <Alert
          action={
            onClose && (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={onClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )
          }
          severity={severity}
        >
          {title && (
            <AlertTitle>
              <strong>{title}</strong>
            </AlertTitle>
          )}
          <MessageBody
            parsedMessage={parsedMessage}
            links={links}
            style={msgStyle}
          />
        </Alert>
      </Stack>
    </Section>
  ) : (
    <Section style={style}>
      <Stack>
        <Alert severity={severity}>
          {title && (
            <AlertTitle>
              <strong>{title}</strong>
            </AlertTitle>
          )}
          <MessageBody
            parsedMessage={parsedMessage}
            links={links}
            style={msgStyle}
          />
        </Alert>
      </Stack>
    </Section>
  );
};

const AlertVariant = React.forwardRef<HTMLDivElement, any>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const AlertStackRenderer = ({
  alerts = [],
}: {
  alerts: Array<IAlertRenderer>;
}) => {
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    return;
  };
  const anchorOrigin = { vertical: "bottom", horizontal: "right" };

  return (
    <Stack sx={{ width: "100%" }} spacing={2}>
      {alerts.map((a: IAlertRenderer, idx: number) => {
        const {
          open = true,
          onClose,
          title,
          message,
          severity,
          links = [],
        } = a || {};
        const parsedMessage = message?.replaceAll("\\n", "\n");
        return (
          <Collapse key={idx} in={open} sx={{ textAlign: "left" }}>
            <Snackbar
              open={alerts.length > 0}
              onClose={handleClose}
              // @ts-ignore
              anchorOrigin={anchorOrigin}
            >
              <AlertVariant
                action={
                  onClose && (
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={onClose}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  )
                }
                severity={severity}
              >
                {title && (
                  <AlertTitle>
                    <strong>{title}</strong>
                  </AlertTitle>
                )}
                <MessageBody parsedMessage={parsedMessage} links={links} />
              </AlertVariant>
            </Snackbar>
          </Collapse>
        );
      })}
    </Stack>
  );
};

export default AlertRenderer;
