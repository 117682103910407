import { useCallback, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
import { StyledFieldWrapper, StyledFileTags } from "../styled-form-components";
import React from "react";
import styled from "styled-components";
import { getMuiIcons, IconTypes } from "../../../utils/@mui-icons";
import { FieldLabelComponent } from "../";
import { Section } from "../../layout";
import { getFilename } from "../../../utils";
import { AlertRenderer } from "../../alerts";
import { useDropzone } from "react-dropzone";

const StyledUploadLabel = styled.label`
  color: var(--primary-black);
  cursor: pointer;
  display: flex;
  border-bottom: 1px solid var(--primary3);
  padding: 10px 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  :hover {
    border-bottom: 2px solid var(--primary1);
    background-color: var(--primary-hover);
  }
`;

export const FileUpload = (props: any) => {
  const {
    name,
    label,
    info,
    errors,
    touchedFields,
    register,
    watch,
    fieldType,
    loading,
    uploadOnChange,
    formDataName,
    fileIsValid,
    rules,
    handleAutosave,
    getValues,
    setValue,
    serverError,
    getFieldState,
    trigger,
    isRequired = false,
    reset,
    validateOnEvent,
    onRemoveFile,
    ...restOfProps
  } = props;
  const fieldError = errors[name];
  const fieldTouched = touchedFields[name];
  const [hasStorageUrl, setHasStorageUrl] = useState(false);
  const selectedFile = watch(name);

  useEffect(() => {
    if (hasStorageUrl) {
      if (fieldError) {
        validateOnEvent && validateOnEvent();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasStorageUrl]);

  const successUploadCallback = (fieldVal: string) => {
    const valIsStorageUrl =
      typeof fieldVal === "string" &&
      fieldVal?.includes("storage.cloud.google.com");
    setHasStorageUrl(valIsStorageUrl);
    setValue(name, fieldVal, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    if (validateOnEvent) {
      validateOnEvent();
    }
  };

  useEffect(() => {
    const selectedFileIsStorageUrl =
      typeof selectedFile === "string" &&
      selectedFile?.includes("storage.cloud.google.com");
    setHasStorageUrl(selectedFileIsStorageUrl);

    const file = selectedFile && selectedFile[0];
    if (file && typeof selectedFile !== "string") {
      const blob = new Blob([file], {
        type: file.type,
      });
      const formData = new FormData();
      formData.append(formDataName, blob, file.name);

      if (handleAutosave) {
        handleAutosave({ [name]: formData });
        validateOnEvent();
      }
      if (uploadOnChange) {
        uploadOnChange(formData, successUploadCallback);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  const icon = fileIsValid ? IconTypes.checkCircle : IconTypes.upload;

  const handleRemoveFile = () => {
    if (onRemoveFile) {
      onRemoveFile();
    }
    setValue(name, undefined, {
      shouldReset: true,
    });
    setHasStorageUrl(false);
  };

  const onDrop = useCallback((acceptedFiles: Array<any>) => {
    const droppedFile = acceptedFiles && acceptedFiles[0];
    if (droppedFile && typeof droppedFile !== "string") {
      const blob = new Blob([droppedFile], {
        type: droppedFile.type,
      });
      const droppedFileFormData = new FormData();
      droppedFileFormData.append(formDataName, blob, droppedFile.name);

      if (handleAutosave) {
        handleAutosave({ [name]: droppedFileFormData });
        validateOnEvent();
      }
      if (uploadOnChange) {
        uploadOnChange(droppedFileFormData, successUploadCallback);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
  });

  return (
    <Section className="dropzone-container">
      <Section
        {...getRootProps()}
        className={`dropzone ${isDragActive ? "active" : ""}`}
      >
        <StyledFieldWrapper>
          <FieldLabelComponent
            label={label}
            showError={fieldError && fieldTouched}
            error={fieldError}
            info={info}
            isRequired={isRequired}
          />
          {hasStorageUrl && (
            <Section
              $flexDirection="row"
              $justifyContent="flex-start"
              $alignItems="center"
              style={{
                fontFamily: "BioSans-Bold",
                color: "var(--koffie-primary-blue)",
              }}
            >
              <StyledFileTags>
                <span style={{ margin: "3px 8px 0 0" }}>
                  {getMuiIcons(IconTypes.attachFile)}
                </span>
                <span>{getFilename(watch(name))}</span>
                <span
                  style={{
                    cursor: "pointer",
                    margin: "2px 0 0 10px",
                  }}
                  onClick={handleRemoveFile}
                >
                  {getMuiIcons(IconTypes.delete)}
                </span>
              </StyledFileTags>
            </Section>
          )}
          {!hasStorageUrl && (
            <Stack sx={{ color: "var(--koffie-primary)", width: "100%" }}>
              {serverError && (
                <Section style={{ margin: "8px 0" }}>
                  <AlertRenderer severity={"error"} message={serverError} />
                </Section>
              )}
              <Section
                $flexDirection="row"
                $alignItems="center"
                style={{
                  backgroundColor:
                    isDragActive && "var(--koffie-primary-hover)",
                }}
              >
                <StyledUploadLabel>
                  <label
                    htmlFor={`upload-${name}`}
                    style={{
                      backgroundColor: "var(--background-grey)",
                      border: "1px solid grey",
                      borderRadius: "2px",
                      fontSize: "0.85em",
                      padding: "3px 5px",
                      cursor: "pointer",
                    }}
                  >
                    Select or Drag & Drop
                  </label>
                  <input
                    id={`upload-${name}`}
                    type="file"
                    {...getInputProps()}
                    {...register(name, rules)}
                  />
                  {!serverError && (
                    <span style={{ marginLeft: "10px" }}>
                      {getMuiIcons(icon)}
                    </span>
                  )}
                </StyledUploadLabel>
                {serverError && (
                  <span
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                    onClick={handleRemoveFile}
                  >
                    {getMuiIcons(IconTypes.delete)}
                  </span>
                )}
              </Section>
              {loading && <LinearProgress />}
            </Stack>
          )}
        </StyledFieldWrapper>
      </Section>
    </Section>
  );
};
