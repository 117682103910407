import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'

import { ModalProvider } from "styled-react-modal";
import { store, persistor } from "./reducers/config/store";
import { ReactNode } from "react";

import "./index.css";

interface Props {
  readonly children: ReactNode;
}

const StoreProvider = ({ children }: Props) => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>{children}</PersistGate>
    </Provider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <StoreProvider>
    <ModalProvider>
      <App />
      <ToastContainer
        pauseOnFocusLoss={true}
        pauseOnHover={true}
        autoClose={6500}
      />
    </ModalProvider>
  </StoreProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
