export const toTitleCase = (str: string) => {
  const excludeList_upper = [
    "dot",
    "dba",
    "zip",
    "mc",
    "fein",
    "id",
    "al",
    "pd",
    "gl",
    "um",
    "uim",
    "bipd",
    "bi",
    "dl",
    "vin",
    "tiv",
    "pip",
    "mp",
    "csl",
    "dob",
  ];
  const excludeList_lower = ["in", "of"];

  return str?.replaceAll("_", " ").replace(/\w\S*/g, function (txt) {
    const lowerTxt = txt.toLowerCase();
    return excludeList_upper.includes(lowerTxt)
      ? txt.toUpperCase()
      : excludeList_lower.includes(lowerTxt)
      ? txt.toLowerCase()
      : txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
};
