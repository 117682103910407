import { Section } from "../../layout";
import {
  StyledTextarea,
  StyledFormWarnings,
  StyledFieldWrapper,
} from "../styled-form-components";
import React from "react";
import { isEmpty } from "lodash";
import { FieldLabelComponent } from "../";
import { ErrorsHandler } from "../errors.handler";

export const Textarea = (props: any) => {
  const {
    name,
    label,
    hint,
    info,
    errors,
    touchedFields,
    register,
    rules,
    handleAutosave,
    getFieldState,
    watch,
    width = "100%",
    isConditionalField = false,
    required = false,
    validateOnEvent,
    setValue
  } = props;
  const fieldError = errors[name];
  const fieldVal = watch(name);
  const fieldTouched =
    touchedFields[name] ||
    (!isEmpty(fieldVal) && !(fieldVal === null) && !(fieldVal === undefined));
  const { onBlur, ref } = register(name, rules);
  const showError = fieldError && fieldTouched;
  const fieldState = getFieldState(name);

  const onBlurValidate = (ev: any) => {
    onBlur(ev);
    if (validateOnEvent) {
      validateOnEvent();
    }
  };

  return (
    <StyledFieldWrapper
      id={name}
      width={width}
      $isConditionalField={isConditionalField}
    >
      <FieldLabelComponent
        label={label}
        info={info}
        hint={hint}
        isRequired={required}
      />
      <Section $flexDirection="row">
        <StyledTextarea
          type="text"
          onBlur={onBlurValidate}
          $isInvalid={fieldState?.invalid}
          ref={ref}
          onChange={(ev: any) => {
            const value = ev.target.value;
            setValue(name, value, {
              shouldValidate: true,
              shouldDirty: true,
              shouldTouch: true,
            });
            handleAutosave({ [name]: value });
            if (validateOnEvent) {
              validateOnEvent();
            }
          }}
        />
      </Section>
      {showError && (
        <StyledFormWarnings>
          {ErrorsHandler(fieldError, label)}
        </StyledFormWarnings>
      )}
    </StyledFieldWrapper>
  );
};
