import { Section } from "../../layout";
import {
  getReactSelectCustomStyles,
  StyledFieldWrapper,
  StyledFormWarnings,
  StyledInputField,
} from "../styled-form-components";
import { AlertRenderer } from "../../alerts";
import AsyncSelect from "react-select/async";
import PoweredByGoogleMenuList from "./google-maps-custom-menu-list";
import { ISelectVal } from "../../../@types";
import React, { useEffect, useState } from "react";
import { FieldLabelComponent } from "../";
import { ErrorsHandler } from "../errors.handler";
import { NoOptionsMessage } from "./dropdown-select";
import {
  some as _some,
  isNil as _isNil,
  values as _values,
  isEmpty,
} from "lodash";
import Google from "../../../assets/google_on_white_hdpi.png";

export const GooglePlaceDetailsToAddressLocationMap = (
  placeDetails: Array<object>
) => {
  let addressMap: any = {};
  if (!placeDetails || isEmpty(placeDetails)) {
    return null;
  }
  placeDetails.forEach((component: any) => {
    return (addressMap = {
      ...addressMap,
      [component.types[0]]: component.short_name,
    });
  });
  const addressLocation = {
    line_1: addressMap.street_number + " " + addressMap.route,
    line_2: "",
    city: addressMap.locality,
    state: addressMap.administrative_area_level_1,
    zip: addressMap.postal_code,
  };
  const _is_invalid = _some(_values(addressLocation), _isNil);
  return {
    ...addressLocation,
    county: addressMap.administrative_area_level_2,
    _is_invalid,
  };
};

export const AsyncDropdownSelect = (props: any) => {
  const {
    label,
    errors,
    touchedFields,
    name,
    field,
    hint,
    info,
    loadOptions,
    isAutocomplete,
    customOnChange,
    longAddress,
    addressLocation,
    watch,
    required = false,
    fieldState,
    noOptionsText = "No Options",
    setSubmitDisabled,
    parentName,
    defaultValue = [],
    addressType,
    showTotalPowerUnits,
  } = props;

  const fieldError = errors[name];
  const fieldVal = watch(name) || [];
  const fieldTouched =
    touchedFields[name] ||
    (!isEmpty(fieldVal) && !(fieldVal === null) && !(fieldVal === undefined));
  const hideIndicator = {
    IndicatorSeparator: () => null,
  };
  const showError = fieldError && fieldTouched;
  // @ts-ignore

  const {
    _is_invalid,
    line_1,
    line_2,
    city,
    state,
    county = "N/A",
    zip,
  } = addressLocation || {};
  const fieldName = parentName ? parentName : name;

  const [search, setSearch] = useState(false);

  useEffect(() => {
    setSubmitDisabled(_is_invalid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_is_invalid]);

  const getLongAddress = (valObj: any) => {
    if (isEmpty(valObj)) {
      return "";
    } else {
      const { line_1, line_2, city, state, zip } = valObj || {};
      const addressComps = [line_1, line_2, city, state, zip];
      const validAddressComps = addressComps?.filter(
        (e: string) => !isEmpty(e)
      );
      return validAddressComps?.join(", ");
    }
  };

  const currentFieldAddress = Array.isArray(defaultValue)
    ? defaultValue?.find((v: any) => v?.address_type === addressType) || {}
    : defaultValue;
  const primaryGarageFieldAddress = Array.isArray(defaultValue)
    ? defaultValue?.find((v: any) => v?.address_type === "primary_garage") || {}
    : defaultValue;

  const address = getLongAddress(currentFieldAddress);

  return (
    <StyledFieldWrapper>
      <FieldLabelComponent label={label} info={info} isRequired={required} />
      {isAutocomplete && !isEmpty(address) && (
        <Section style={{ marginBottom: "10px" }}>
          <StyledInputField value={address} disabled />
          {!search && (
            <span
              style={{
                cursor: "pointer",
                fontSize: "0.7em",
                marginTop: "10px",
              }}
              onClick={() => setSearch(true)}
            >
              <u>CHANGE ADDRESS</u>
            </span>
          )}
        </Section>
      )}
      {(isEmpty(address) || search) && loadOptions && (
        <AsyncSelect
          cacheOptions
          loadOptions={loadOptions}
          defaultOptions
          placeholder={
            isAutocomplete
              ? "Start typing to search an address location..."
              : hint
          }
          components={
            isAutocomplete
              ? {
                  ...hideIndicator,
                  DropdownIndicator: () => null,
                  MenuList: PoweredByGoogleMenuList,
                  NoOptionsMessage: (props: any) => (
                    <NoOptionsMessage
                      noOptionsText={isAutocomplete ? "..." : noOptionsText}
                      {...props}
                    />
                  ),
                }
              : hideIndicator
          }
          styles={getReactSelectCustomStyles(fieldState?.invalid)}
          {...props}
          defaultValue={address}
          onChange={(option: ISelectVal) => {
            const { value } = option;
            if (customOnChange) {
              customOnChange(value, field?.onChange, fieldName, addressType);
              setSearch(false);
            }
          }}
        />
      )}
      {isAutocomplete && showTotalPowerUnits && (
        <Section style={{ marginTop: "20px" }}>
          <FieldLabelComponent label="Total Power Units" isRequired={true} />
          <StyledInputField
            defaultValue={
              currentFieldAddress?.address_type === "physical"
                ? primaryGarageFieldAddress?.total_power_units
                : currentFieldAddress?.total_power_units
            }
            type="number"
            onChange={(ev: any) =>
              customOnChange(
                false,
                field?.onChange,
                fieldName,
                addressType === "physical" ? "primary_garage" : addressType,
                {
                  ...currentFieldAddress,
                  total_power_units: Number(ev?.target?.value),
                }
              )
            }
          />
        </Section>
      )}
      {showError && (
        <StyledFormWarnings>
          {ErrorsHandler(fieldError, label)}
        </StyledFormWarnings>
      )}
      {isAutocomplete && (
        <Section
          style={{
            marginTop: "15px",
            textAlign: "left",
            color: "var(--primary-black)",
            fontSize: "0.95em",
          }}
        >
          {longAddress && !isEmpty(longAddress) && (
            <>
              <span style={{ fontWeight: "bold" }}>Address Found:</span>
              <span>{longAddress}</span>
            </>
          )}
          {addressLocation && !isEmpty(addressLocation) && (
            <>
              <span style={{ fontWeight: "bold" }}>Address Found:</span>
              <span>
                {line_1?.includes("undefined")
                  ? line_1.replace("undefined", "** missing street address **")
                  : line_1}
              </span>
              <span>{`${city} (${county})`}</span>
              <span>{`${state} ${zip ? zip : "** missing zip code **"}`}</span>
            </>
          )}
          {_is_invalid && (
            <Section style={{ marginTop: "15px" }}>
              <AlertRenderer
                severity="error"
                message="The address selected is not complete. Start with the street/building number when entering the address in the search bar."
              />
            </Section>
          )}
        </Section>
      )}
    </StyledFieldWrapper>
  );
};
