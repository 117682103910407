import React, { useEffect } from "react";
import {
  StyledInputField,
  StyledFieldWrapper,
  StyledFormWarnings,
} from "../styled-form-components";
import { Section } from "../../layout";
import { isEmpty } from "lodash";
import {
  FormatPhone,
  CurrencyFormatter,
  FormatCurrencyNumber,
} from "../../../utils";
import { FieldLabelComponent } from "../";
import { ErrorsHandler } from "../errors.handler";

export const InputField = (props: any) => {
  const {
    name,
    label,
    hint,
    info,
    errors,
    touchedFields,
    register,
    rules,
    handleAutosave,
    watch,
    // eslint-disable-next-line
    setValue,
    getFieldState,
    formatPhone = false,
    formatCurrency = false,
    formatCurrencyNumber = false,
    width = "100%",
    isPassword = false,
    isConditionalField = false,
    required = false,
    validateOnEvent,
    disabled = false,
    controlled = false,
    hasControllerSwitch = false,
    defaultValue,
    initialValue = "",
    storeVal = "",
    isPercentage = false,
    // reset,
  } = props;
  const fieldError = errors[name];
  const fieldVal = watch(name);
  const fieldTouched =
    touchedFields[name] ||
    (!isEmpty(fieldVal) && !(fieldVal === null) && !(fieldVal === undefined));
  const { onBlur, ref } = register(name, rules);
  const showError = fieldError && fieldTouched && !disabled;
  const fieldState = getFieldState(name);

  useEffect(() => {
    if (hasControllerSwitch) {
      const fieldValue = controlled ? storeVal : initialValue;
      setValue(name, fieldValue, {
        shouldValidate: controlled,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlled]);

  return !controlled ? (
    <StyledFieldWrapper
      id={name}
      width={width}
      $isConditionalField={isConditionalField}
    >
      <FieldLabelComponent
        label={label}
        info={info}
        hint={hint}
        isRequired={required}
      />
      <Section $flexDirection="row" $alignItems="center">
        {formatCurrency && <span style={{ marginRight: "6px" }}>$</span>}
        <StyledInputField
          role="input"
          type={isPassword ? "password" : "text"}
          $isInvalid={fieldState?.invalid}
          onBlur={(ev: any) => {
            onBlur(ev);
            validateOnEvent();
          }}
          defaultValue={
            formatCurrencyNumber
              ? CurrencyFormatter(Number(defaultValue))
              : isPercentage
              ? defaultValue * 100
              : defaultValue
          }
          ref={ref}
          disabled={controlled ? controlled : disabled ? disabled : false}
          onChange={(ev: any) => {
            let value = ev?.target?.value;
            if (formatPhone) {
              value = FormatPhone(value.trim());
            }
            if (formatCurrency) {
              value = CurrencyFormatter(Number(value));
            }
            if (formatCurrencyNumber) {
              value = FormatCurrencyNumber(value);
            }
            if (value === "") {
              value = null;
            }
            setValue(name, value, {
              shouldValidate: true,
              shouldDirty: true,
              shouldTouch: true,
            });
            handleAutosave({
              [name]: isPercentage
                ? Number(value?.trim()) / 100
                : value?.trim(),
            });
          }}
        />
      </Section>
      {showError && (
        <StyledFormWarnings>
          {ErrorsHandler(fieldError, label)}
        </StyledFormWarnings>
      )}
    </StyledFieldWrapper>
  ) : (
    <></>
  );
};
