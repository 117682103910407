import { Section } from "../../layout";
import { FieldLabelComponent } from "../field.label";
import {
  StyledFieldWrapper,
  StyledFormWarnings,
} from "../styled-form-components";
import { ErrorsHandler } from "../errors.handler";
import React from "react";
import { isEmpty } from "lodash";
import { StyledButton } from "../../buttons";
import { Modal } from "../../index";
import FormContentRenderer from "../content-renderer";
import { getMuiIcons, toTitleCase, IconTypes } from "../../../utils";

const ModalContent = (props: any) => {
  const {
    entityFields,
    handleUpdateEntity,
    mappedField,
    $toggleModal,
    initialValues,
    entities,
    additionalProps,
  } = props || {};

  const { handleGetAutocompleteOptions, handleAddressSelect, addressLocation } =
    additionalProps || {};

  return (
    <FormContentRenderer
      formFields={entityFields?.map((e: any) => {
        const { key, ...restOfE } = e || {}
        const autocompleteProps = {
          loadOptions: handleGetAutocompleteOptions,
          customOnChange: handleAddressSelect,
          addressLocation,
          parentName: mappedField,
          defaultValue: initialValues?.address,
        };
        return {
          ...restOfE,
          name: key,
          ...autocompleteProps,
        };
      })}
      renderWithoutFormTags={true}
      formSubmitLabel={`${!isEmpty(entities) ? "update" : "add"}`}
      autosave={false}
      initialValues={initialValues}
      onFormSubmit={(data: any) => {
        handleUpdateEntity(data, mappedField);
        if ($toggleModal) {
          $toggleModal();
        }
      }}
    />
  );
};

export const Entity = (props: any) => {
  const {
    label,
    hint,
    info,
    errors,
    touchedFields,
    modalTitle,
    name,
    mappedField,
    field,
    width = "100%",
    nonControlledProps = {},
    entityFields,
    handleUpdateEntity,
    required = false,
    entities,
    additionalProps,
  } = props;
  const fieldError = errors[name];
  const fieldVal = field?.value;
  const fieldTouched =
    touchedFields[name] ||
    (!isEmpty(fieldVal) && !(fieldVal === null) && !(fieldVal === undefined));
  const showError = fieldError && fieldTouched;
  const { setValue, watch } = nonControlledProps;
  return (
    <StyledFieldWrapper width={width}>
      <FieldLabelComponent
        label={label}
        info={info}
        hint={hint}
        isRequired={required}
      />
      {!isEmpty(entities) &&
        entities?.map((e: any, idx: number) => {
          const keys = Object.keys(e);

          return (
            <Section
              key={idx}
              style={{
                border: "1px solid black",
                padding: "10px 15px",
                marginTop: "10px",
                marginRight: "15px",
                marginBottom: "15px",
              }}
              $flexDirection="row"
              $justifyContent="space-between"
            >
              <Section>
                {keys?.map((k: string, idx: number) => {
                  return k !== "id" ? (
                    <Section key={idx} $flexDirection="row">
                      <span style={{ marginRight: "10px" }}>
                        <b>{toTitleCase(k) + ": "}</b>
                      </span>
                      <span>{ (typeof e?.[k] === "boolean" ? e?.[k] ? "Yes" : "No" : e?.[k]) || "-"}</span>
                    </Section>
                  ) : (
                    <Section key={idx}></Section>
                  );
                })}
              </Section>
              <Modal
                title={`Edit ${modalTitle ? modalTitle : label}`}
                Trigger={({ onClick }: any) => {
                  return (
                    <Section
                      $flexDirection="row"
                      style={{
                        cursor: "pointer",
                        fontSize: "0.8em",
                        width: "50px",
                      }}
                      onClick={onClick}
                    >
                      {getMuiIcons(IconTypes.edit)}EDIT
                    </Section>
                  );
                }}
                size={window.innerWidth > 400 ? "sm" : "lg"}
              >
                <ModalContent
                  entityFields={entityFields}
                  mappedField={name || mappedField}
                  handleUpdateEntity={handleUpdateEntity}
                  initialValues={e || {}}
                  entities={entities}
                  setValue={setValue}
                  watch={watch}
                  additionalProps={additionalProps}
                />
              </Modal>
            </Section>
          );
        })}
      <Section $flexDirection="row">
        <Modal
          title={modalTitle ? modalTitle : label}
          Trigger={({ onClick }: any) => {
            return (
              <StyledButton
                label={`Add`}
                btn_type="primary"
                onClick={onClick}
              />
            );
          }}
          size={window.innerWidth > 400 ? "sm" : "lg"}
        >
          <ModalContent
            entityFields={entityFields}
            mappedField={name || mappedField}
            handleUpdateEntity={handleUpdateEntity}
            entities={entities}
            setValue={setValue}
            watch={watch}
            additionalProps={additionalProps}
          />
        </Modal>
      </Section>
      {showError && (
        <StyledFormWarnings style={{ marginTop: "5px" }}>
          {ErrorsHandler(fieldError, label)}
        </StyledFormWarnings>
      )}
    </StyledFieldWrapper>
  );
};
