import { createReducer, createActions } from "reduxsauce";
import { v4 as uuidv4 } from "uuid";

const { Types, Creators } = createActions({
  getFormConfigRequest: null,
  getFormConfigSuccess: ["payload"],
  getFormConfigFailure: ["error"],
  saveRequest: ["efnol", "isSubmit"],
  saveSuccess: ["payload"],
  saveFailure: ["error"],
  updateFormData: ["dataObj"],
  updateEntity: ["dataObj", "mappedField"],
  uploadFileSuccessCallback: ["storageURL", "mappedField"],
  removeFile: ["storageURL", "mappedField"],
  resetForm: null,
});

export const BaseTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  config: {
    loading: false,
    error: null,
    data: null,
  },
  form: {
    loading: false,
    error: null,
    data: {},
  },
  upload: {
    loading: false,
    error: null,
  },
};

const getFormConfigRequest = (state: any) => ({
  ...state,
  config: {
    ...state.config,
    loading: true,
    data: null,
  },
});

const getFormConfigSuccess = (state: any, { payload }: any) => {
  const initialValues = payload?.initialValues || {}
  return ({
    ...state,
    config: {
      ...state.config,
      loading: false,
      data: payload,
    },
    form: {
      loading: false,
      error: null,
      data: {
        ...initialValues,
        id: uuidv4()?.replaceAll("-","")
      },
    }
  });
}

const getFormConfigFailure = (state: any, { error }: any) => ({
  ...state,
  config: {
    ...state.config,
    loading: false,
    data: null,
    error,
  },
});

const saveRequest = (state: any) => ({
  ...state,
  form: {
    ...state.form,
    loading: true,
  },
});

const saveSuccess = (state: any, { payload }: any) => ({
  ...state,
  form: {
    ...state.form,
    loading: false,
    data: payload,
  },
});

const saveFailure = (state: any, { error }: any) => ({
  ...state,
  form: {
    ...state.form,
    loading: false,
    data: null,
    error,
  },
});

const updateFormData = (state: any, { dataObj }: any) => {
  return {
    ...state,
    form: {
      ...state.form,
      data: {
        ...state?.form?.data,
        ...dataObj,
      },
    },
  };
};

const uploadFileSuccessCallback = (
  state: any,
  { storageURL, mappedField }: any
) => {
  const fileList = state?.form?.data?.[mappedField] || [];
  return {
    ...state,
    form: {
      ...state?.form,
      data: {
        ...state?.form?.data,
        [mappedField]: [...fileList, storageURL],
      },
    },
  };
};

const removeFile = (state: any, { storageURL, mappedField }: any) => {
  const fileList = state?.form?.data?.[mappedField] || [];
  const updatedFileList = fileList?.filter((e: string) => e !== storageURL);
  return {
    ...state,
    form: {
      ...state?.form,
      data: {
        ...state?.form?.data,
        [mappedField]: updatedFileList,
      },
    },
  };
};

const flattenOptions = (data: any) => {
  const keys = Object.keys(data) || [];
  let updatedObj: any = {};
  keys?.forEach((k: string) => {
    if (data?.[k]?.value) {
      updatedObj[k] = data?.[k]?.value;
    } else {
      updatedObj[k] = data?.[k];
    }
  });
  return updatedObj;
};

const updateEntity = (state: any, { dataObj, mappedField }: any) => {
  const updatedObj = flattenOptions(dataObj) || {};
  const entities = state?.form?.data?.[mappedField] || [];
  const entityWithId = {
    ...updatedObj,
    id: dataObj?.id ? dataObj?.id : uuidv4()?.replaceAll("-",""),
  };

  const entitiesIds = entities?.map((e: any) => e?.id);
  const updatedEntities = entitiesIds?.includes(entityWithId?.id)
    ? entities?.map((e: any) => {
        if (e?.id === entityWithId?.id) {
          return entityWithId;
        } else {
          return e;
        }
      })
    : [...entities, entityWithId];
  return {
    ...state,
    form: {
      ...state?.form,
      data: {
        ...state?.form?.data,
        [mappedField]: updatedEntities,
      },
    },
  };
};

const resetForm = () => INITIAL_STATE;

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_FORM_CONFIG_REQUEST]: getFormConfigRequest,
  [Types.GET_FORM_CONFIG_SUCCESS]: getFormConfigSuccess,
  [Types.GET_FORM_CONFIG_FAILURE]: getFormConfigFailure,
  [Types.SAVE_REQUEST]: saveRequest,
  [Types.SAVE_SUCCESS]: saveSuccess,
  [Types.SAVE_FAILURE]: saveFailure,
  [Types.UPDATE_FORM_DATA]: updateFormData,
  [Types.UPLOAD_FILE_SUCCESS_CALLBACK]: uploadFileSuccessCallback,
  [Types.REMOVE_FILE]: removeFile,
  [Types.UPDATE_ENTITY]: updateEntity,
  [Types.RESET_FORM]: resetForm,
});
