import MUISlider from "@mui/material/Slider";
import { Section } from "../../layout";
import styled from "styled-components";

interface ISlider {
  defaultValue?: number;
  step: number;
  min: number;
  max: number;
  marks?: any;
  valueLabelDisplay?: "auto" | "on";
  valueLabelFormat?: any;
  onChange?:
    | ((event: Event, value: number | number[], activeThumb: number) => void)
    | undefined;
  value: number | string | number[];
}

const KoffieSlider = styled(MUISlider)(({ theme }) => ({
  color: "var(--primary1)",
  padding: "15px 0",
  "& .MuiSlider-track": {
    height: 2,
    border: "none",
    backgroundColor: "var(--primary3)",
  },
  "& .MuiSlider-thumb": {
    height: 15,
    width: 15,
    backgroundColor: "var(--primary3)",
    "&:focus, &:hover, &.Mui-active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -10,
    color: "var(--primary-black)",
    backgroundColor: "var(--yellow)",
  },
  "& .MuiSlider-rail": {
    height: 2,
    opacity: 0.5,
    backgroundColor: "var(--info-blue)",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "var(--primary1)",
    height: 8,
    width: 1,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "var(--primary3)",
    },
  },
}));

export const Slider = ({
  step,
  min,
  max,
  marks = false,
  valueLabelDisplay = "auto",
  valueLabelFormat,
  onChange,
  value,
}: ISlider) => {
  const val = typeof value === "string" ? Number(value) : value;
  return (
    <Section>
      <KoffieSlider
        step={step}
        min={min}
        max={max}
        marks={marks}
        valueLabelDisplay={valueLabelDisplay}
        valueLabelFormat={valueLabelFormat}
        onChange={onChange}
        value={val}
      />
    </Section>
  );
};
