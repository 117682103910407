import styled, { css } from "styled-components";
import { Section } from "../layout";
import { devices } from "../../utils";

export const StyledFormSectionTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 1.5em;
`;

const baseFont = css`
  font-size: 0.95em;
  font-family: inherit;
  color: var(--primary-black);
`;

const baseFocus = css`
  &:focus {
    outline: none;
    border: 2px solid var(--primary3);
  }
`;

const baseField = css`
  background-color: rgba(34, 31, 32, 0.035);
  color: var(--primary-black);
`;

export const StyledFieldWrapper = styled(Section)`
  margin-bottom: ${({ $isConditionalField, $isStandaloneField }) =>
    $isConditionalField ? 0 : $isStandaloneField ? 10 : 20}px;
  align-items: flex-start;
  width: 100%;
  justify-content: inherit;
  @media ${devices.laptop} {
    width: ${({ width }: any) => width || "100%"};
  }
`;

export const StyledFormPadding = styled(Section)`
  padding-bottom: 1%;
`;

export const getReactSelectCustomStyles = (
  isInvalid: boolean,
  isDisabled?: boolean
) => ({
  control: (base: any, state: any) => ({
    ...base,
    width: "100%",
    border: "none",
    borderRadius: 0,
    borderBottom: isDisabled
      ? "none"
      : isInvalid
      ? "1px solid var(--warning-red)"
      : "1px solid var(--primary3)",
    boxShadow: "none",
    marginTop: "4px",
    textAlign: "left",
    fontSize: "0.95em",
    color: "var(--primary-black)",
    backgroundColor: "rgba(34, 31, 32, 0.035)",
    "&:hover": {
      border: "2px solid var(--primary3)",
      cursor: "pointer",
    },
    "&:focus": {
      outline: "none",
      border: "2px solid var(--primary3)",
    },
  }),
  container: (base: any) => ({
    ...base,
    width: "100%",
    fontFamily: "var(--base-font-family)",
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: "2px 4px",
    fontFamily: "var(--base-font-family)",
  }),
  option: (base: any, state: any) => ({
    ...base,
    backgroundColor: state.isFocused ? "var(--primary-hover)" : "white",
    color: "var(--primary-black)",
    textAlign: "left",
    fontSize: "0.95em",
    fontFamily: "var(--base-font-family)",
    "&:hover": {
      backgroundColor: "var(--primary-hover)",
      cursor: "pointer",
    },
  }),
  multiValue: (base: any) => ({
    ...base,
    backgroundColor: "var(--primary3)",
  }),
  multiValueLabel: (base: any) => ({
    ...base,
    color: "white",
    fontSize: "0.95em",
  }),
  multiValueRemove: (base: any) => ({
    ...base,
    color: "white",
    ":hover": {
      backgroundColor: "var(--primary3)",
      color: "white",
    },
  }),
});

export const StyledHintSpan = styled.span`
  ${baseFont}
  padding: 2px 0 5px;
  text-align: left;
  color: #808080;
`;

export const StyledToggleLabel = styled.span`
  ${baseFont}
  color: #808080;
`;

export const StyledLabel = styled.label`
  font-weight: bold;
  font-size: 1.05em;
  color: var(--koffie-black);
  margin-right: 10px;
  text-align: left;
`;

export const StyledLabelWrapper = styled(Section)`
  flex-direction: row;
`;

export const StyledLabelInfoIcon = styled.span`
  color: var(--koffie-grey);
  cursor: pointer;
  :hover {
    color: var(--koffie-primary);
  }
`;

export const StyledFormSection = styled(Section)`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: var(--base-box-shadow);
  flex-direction: column;
  height: min-content;
  margin-bottom: var(--base-margin);
  @media ${devices.mobileS} {
    padding: 2% 3%;
  }
  @media ${devices.tablet} {
    padding: 3% 5%;
  }
  @media ${devices.laptop} {
    padding: 3.5% 5.5%;
  }
  @media ${devices.laptopL} {
    padding: 4% 5.5%;
  }
`;

export const StyledFormContentWrapper = styled(Section)`
  margin: 1.5em 0 2.5em;
`;

export const StyledTextarea = styled.textarea<any>`
  margin: 5px 0;
  border: none;
  border-bottom: ${({ $isInvalid }: { $isInvalid?: boolean }) =>
    $isInvalid
      ? "1px solid var(--warning-red)"
      : "1px solid var(--koffie-primary-navy)"};
  padding: 8px;
  width: 100%;
  min-height: 100px;
  ${baseField}
  ${baseFont}
  ${baseFocus}
`;

export const StyledInputField = styled.input<any>`
  height: 38px;
  margin-top: 4px;
  padding: 8px 6px;
  border: none;
  border-bottom: ${({
    disabled,
    $isInvalid,
  }: {
    disabled?: boolean;
    $isInvalid?: boolean;
  }) =>
    disabled
      ? "none"
      : $isInvalid
      ? "1px solid var(--warning-red)"
      : "1px solid var(--primary3)"};
  width: 100%;
  box-sizing: border-box;
  ${baseField}
  ${baseFont}
  &:hover {
    border-bottom: ${({ disabled }) =>
      !disabled && "2px solid var(--primary1)"};
  }
  ${baseFocus}
`;

export const StyledSelect = styled.select`
  margin: 5px 0;
  padding: 10px 5px;
  border: none;
  border-bottom: 1px solid #f1f7fa;
  font-size: 1em;
  width: 100%;
  box-sizing: border-box;
  ${baseFont}
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const StyledFormWarnings = styled.span`
  font-size: 0.7em;
  color: red;
  text-align: left;
`;

export const StyledDatePickerWrapper = styled(Section)`
  .react-datepicker {
    border: 1px solid var(--primary1);
    box-shadow: var(--base-box-shadow);
    background-color: var(--primary1);
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__header {
    border-bottom: 1px solid var(--background-primary);
    background-color: var(--primary1);
    padding: 15px 0;
  }
  .react-datepicker__header__dropdown {
    color: white;
  }
  .react-datepicker__navigation {
    margin: 10px 5px;
  }
  .react-datepicker__navigation-icon::before {
    border-color: #ffffff;
  }
  .react-datepicker__current-month {
    font-size: 1.4em;
    color: #ffffff;
  }
  .react-datepicker__day-name {
    font-size: 1.1em;
    color: #ffffff;
  }
  .react-datepicker__day {
    padding: 5px;
    font-size: 1.2em;
    color: var(--primary-black);
    &:hover {
      font-weight: bold;
      background-color: var(--primary3);
      color: #ffffff;
    }
  }
  .react-datepicker__day--today {
    background-color: var(--primary2);
    border-radius: 4px;
    color: #ffffff;
  }
  .react-datepicker__day--selected {
    background-color: var(--primary1);
    color: #ffffff;
  }
  .react-datepicker__day--disabled {
    cursor: not-allowed;
    color: #909090;
    &:hover {
      font-weight: 400;
      background-color: transparent;
    }
  }
  .react-datepicker__day--keyboard-selected {
    background-color: var(--primary1);
    color: #ffffff;
  }
  .react-datepicker__month-container {
    border-radius: 6px;
    border-bottom-right-radius: ${({ $showTimeInput }) =>
      $showTimeInput ? 0 : 4}px;
    border-bottom-left-radius: ${({ $showTimeInput }) =>
      $showTimeInput ? 0 : 4}px;
    background-color: white;
  }
  .react-datepicker__input-time-container {
    margin-top: 10px;
  }
  .react-datepicker-time__caption {
    color: white;
    font-weight: bold;
  }
  .react-datepicker-time__input {
    min-width: 90px;
    border: none;
    padding: 3px 6px;
    border-radius: 3px;
    font-family: Roboto;
    font-size: 1em;
  }
`;

export const CheckboxWrapper = styled(Section)`
  width: auto;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  input[type="checkbox"] {
    margin: 0 10px 0 0;
    width: 20px;
    height: 20px;
    accent-color: var(--primary3);
  }
`;

export const StyledFileTags = styled.div`
  padding: 6px 10px;
  background-color: var(--primary3);
  color: white;
  font-size: 0.95em;
  font-weight: bold;
  text-align: left;
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
