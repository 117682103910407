import { ReactNode } from "react";
import { Section } from "ui-bank";

import "react-datepicker/dist/react-datepicker.css";

interface Props {
  readonly children: ReactNode;
}

const RootLayout = ({ children }: Props) => {
  return (
    <main>
      <Section
        style={{
          height: "100vh",
        }}
      >
        {children}
      </Section>
    </main>
  );
};

export default RootLayout;
