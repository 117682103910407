import api from "./api";
import * as baseService from "./base";

const getRoot = () => api.get("");

const apiPaths = {
  getRoot,
  ...baseService,
};

export default apiPaths;
