import { Section } from "../layout";
import { StyledButton } from "../buttons";
import React from "react";

const VinDecodeModalContent = ({ vinSearchProps, $toggleModal }: any) => {
  return (
    <div>
      {`Do you want to add the following (${vinSearchProps?.vin_list_decoded?.length}) vehicles to this schedule? This will replace any prior entered vehicles.`}
      <Section $flexDirection="row" $flexWrap="wrap">
        {vinSearchProps?.vin_list_decoded?.map((veh: any) => (
          <Section
            key={veh?.vin}
            style={{
              margin: "10px 20px 10px 0 ",
              width: "250px",
              border: "1px solid var(--primary3)",
            }}
          >
            <Section
              style={{
                padding: "6px 8px",
                color: "white",
                backgroundColor: "var(--primary3)",
              }}
            >
              {veh?.vin}
            </Section>
            <Section style={{ padding: "6px 8px" }}>
              <span>
                <b>Make: </b>
                {veh?.make}
              </span>
              <span>
                <b>Model: </b>
                {veh?.model}
              </span>
              <span>
                <b>Year: </b>
                {veh?.year}
              </span>
              <span>
                <b>Vehicle Class: </b>
                {veh?.vehicle_rate_class}
              </span>
            </Section>
          </Section>
        ))}
      </Section>
      <Section
        $flexDirection="row"
        $justifyContent="space-between"
        style={{ marginTop: "10px" }}
      >
        <StyledButton
          label="No"
          btn_type="secondary"
          onClick={() => {
            if ($toggleModal) {
              $toggleModal();
            }
            if (vinSearchProps?.onCancelModal) {
              vinSearchProps?.onCancelModal();
            }
          }}
        />
        <StyledButton
          label="Yes"
          btn_type="primary"
          onClick={() => {
            if ($toggleModal) {
              $toggleModal();
            }
            if (vinSearchProps?.onConfirmModal) {
              vinSearchProps?.onConfirmModal(vinSearchProps?.vin_list_decoded);
            }
          }}
        />
      </Section>
    </div>
  );
};

export default VinDecodeModalContent;
