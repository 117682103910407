export const KoffieDict = {
  additional_info: "additional_info",
  additional_files: "additional_files",
  additional_insureds: "additional_insureds",
  additional_named_insureds: "additional_named_insureds",
  additional_operation_type: "additional_operation_type",
  address: "address",
  address_city: "address_city",
  address_state: "address_state",
  address_street: "address_street",
  address_county: "address_county",
  address_zip: "address_zip",
  agencies: "agencies",
  agencies_notified: "agencies_notified",
  agency_controlled: "agency_controlled",
  agency_controlled_years: "agency_controlled_years",
  agency_id: "agency_id",
  agency_name: "agency_name",
  all_power_units: "all-power-units",
  all_trailers: "all-trailers",
  allow_passengers: "allow_passengers",
  allow_passengers_frequency: "allow_passengers_frequency",
  allow_passengers_policy: "allow_passengers_policy",
  annual_cost_hire: "annual_cost_hire",
  applicant_name: "applicant_name",
  applicant_email: "applicant_email",
  applicant_phone: "applicant_phone",
  auto_liability_limit: "auto_liability_limit",
  bmc_91x_filing: "bmc_91x_filing",
  business_use: "business_use",
  camera_vendor: "camera_vendor",
  capable_dumping_load: "capable_dumping_load",
  coll_deductible: "coll_deductible",
  commodities_hauled: "commodities_hauled",
  comp_deductible: "comp_deductible",
  company_name: "company_name",
  claims_contact_email: "claims_contact_email",
  claims_contact_name: "claims_contact_name",
  claims_contact_phone: "claims_contact_phone",
  claims_contact_title: "claims_contact_title",
  current_location: "current_location",
  damage_description: "damage_description",
  damage_estimation: "damage_estimation",
  dba_name: "dba_name",
  declared_value: "declared_value",
  dl_state: "dl_state",
  dl_number: "dl_number",
  dob: "dob",
  double_triple_hauls: "double_triple_hauls",
  driver: "driver",
  drivers: "drivers",
  driver_count: "driver_count",
  driver_files_compliance: "driver_files_compliance",
  driver_schedule_file_url: "driver_schedule_file_url",
  drug_alcohol_compliance: "drug_alcohol_compliance",
  effective_date: "effective_date",
  eld_vendor: "eld_vendor",
  email: "email",
  employees_mod: "employees_mod",
  equipment_lease_to_others: "equipment_lease_to_others",
  equipment_lease_to_others_detail: "equipment_lease_to_others_detail",
  equipment_mod: "equipment_mod",
  expiring_premium: "expiring_premium",
  expiring_premium_AL: "expiring_premium_AL",
  expiring_premium_CARGO: "expiring_premium_CARGO",
  expiring_premium_GL: "expiring_premium_GL",
  expiring_premium_PD: "expiring_premium_PD",
  fein: "fein",
  form_e_filing: "form_e_filing",
  form_e_filing_states: "form_e_filing_states",
  freight_broker_forwarder: "freight_broker_forwarder",
  freight_broker_forwarder_detail: "freight_broker_forwarder_detail",
  future_growth_plan: "future_growth_plan",
  garage_address_city: "garage_address_city",
  garage_address_location_DROP: "garage_address_location_DROP",
  garage_address_state: "garage_address_state",
  garage_address_street: "garage_address_street",
  garage_address_county: "garage_address_county",
  garage_address_zip: "garage_address_zip",
  garage_addresses: "garage_addresses",
  garage_zip: "garage_zip",
  has_camera: "has_camera",
  has_eld: "has_eld",
  hazardous_waste_hauls: "hazardous_waste_hauls",
  hazardous_waste_hauls_detail: "hazardous_waste_hauls_detail",
  hired_non_owned_auto_coll_deductible: "hired_non_owned_auto_coll_deductible",
  hired_non_owned_auto_comp_deductible: "hired_non_owned_auto_comp_deductible",
  hired_non_owned_auto_pd: "hired_non_owned_auto_pd",
  hired_non_owned_auto_liability: "hired_non_owned_auto_liability",
  hired_non_owned_auto_limit: "hired_non_owned_auto_limit",
  is_employee: "is_employee",
  incident_date: "incident_date",
  incident_description: "incident_description",
  incident_location: "incident_location",
  incident_time: "incident_time",
  injuries: "injuries",
  injuries_num: "injuries_num",
  injury_description: "injury_description",
  insured_company_name: "insured_company_name",
  insured_contact_email: "insured_contact_email",
  insured_contact_name: "insured_contact_name",
  insured_contact_phone: "insured_contact_phone",
  insured_dot: "insured_dot",
  insured_vehicles: "insured_vehicles",
  is_koffie_insured: "is_koffie_insured",
  kscore: "kscore",
  loan_lease_gap: "loan_lease_gap",
  loss_runs_cy_file_url: "loss_runs_cy_file_url",
  loss_runs_py1_file_url: "loss_runs_py1_file_url",
  loss_runs_py2_file_url: "loss_runs_py2_file_url",
  make: "make",
  management_mod: "management_mod",
  med_pay: "med_pay",
  metro_areas_travelled: "metro_areas_travelled",
  name: "name",
  organization_type: "organization_type",
  other_filings: "other_filings",
  oversize_overweight_hauls: "oversize_overweight_hauls",
  oversize_overweight_hauls_detail: "oversize_overweight_hauls_detail",
  owner_name: "owner_name",
  owner_op_contractors: "owner_op_contractors",
  owner_other_trans_business: "owner_other_trans_business",
  owner_other_trans_business_detail: "owner_other_trans_business_detail",
  owner_years_experience: "owner_years_experience",
  pd_deductible: "pd_deductible",
  phone: "phone",
  physical_damage: "physical_damage",
  plate_state: "plate_state",
  plate_number: "plate_number",
  policy_number: "policy_number",
  power_unit_count: "power_unit_count",
  prior_term_loss_ratio: "prior_term_loss_ratio",
  previous_cancellation: "previous_cancellation",
  previous_cancellation_detail: "previous_cancellation_detail",
  primary: "primary",
  primary_dot: "primary_dot",
  primary_operation_type: "primary_operation_type",
  primary_mc_number: "primary_mc_number",
  producer_name: "producer_name",
  producer_email: "producer_email",
  producer_phone: "producer_phone",
  radius_of_operation: "radius_of_operation",
  rating_class: "rating_class",
  recent_equipment_addition: "recent_equipment_addition",
  recent_equipment_removal: "recent_equipment_removal",
  regions_travelled: "regions_travelled",
  registration_proof: "registration_proof",
  renewal_term: "renewal_term",
  report_number: "report_number",
  reporter_email: "reporter_email",
  reporter_name: "reporter_name",
  reporter_phone: "reporter_phone",
  safety_org_mod: "safety_org_mod",
  select_all: "select-all",
  specialized_use: "specialized_use",
  stay_loaded: "stay_loaded",
  supporting_documents: "supporting_documents",
  telephone: "telephone",
  third_party_vehicles: "third_party_vehicles",
  total_power_units: "total_power_units",
  trailer_count: "trailer_count",
  trailer_interchange: "trailer_interchange",
  trailer_interchange_coll_deductible: "trailer_interchange_coll_deductible",
  trailer_interchange_limit: "trailer_interchange_limit",
  uim_limit: "uim_limit",
  uim_bi_limit: "uim_bi_limit",
  um_bi_pd_limit: "um_bi_pd_limit",
  um_bipd_limit: "um_bipd_limit",
  um_limit: "um_limit",
  um_pd_limit: "um_pd_limit",
  usage: "usage",
  vehicles: "vehicles",
  vehicle_count: "vehicle_count",
  vehicle_owner_name: "vehicle_owner_name",
  vehicle_schedule_file_url: "vehicle_schedule_file_url",
  vehicle_type: "vehicle_type",
  vin: "vin",
  vin_schedule_complete: "vin_schedule_complete",
  vin_schedule_complete_detail: "vin_schedule_complete_detail",
  witnesses: "witnesses",
  within_chicago_limits: "within_chicago_limits",
  year: "year",
  years_with_authority: "years_with_authority",
  years_in_business: "years_in_business",
  zone_rated: "zone_rated",
};
