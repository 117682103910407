import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "@redux-saga/core";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { createLogger } from "redux-logger";
import persistReducer from "redux-persist/lib/persistReducer";
import persistStore from "redux-persist/lib/persistStore";
import storage from "./storage";
import rootReducer from "../root";
import sagas from "sagas";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "react-router-redux";

const persistConfig = {
  key: "root",
  storage,
  version: 0.1,
  stateReconciler: autoMergeLevel2,
  // Optionally, you can whitelist or blacklist certain reducers
  // whitelist: ['reducer1', 'reducer2'],
  // blacklist: ['reducer3'],
};

export const history = createBrowserHistory();

const persistedRootReducer = persistReducer(
  persistConfig,
  rootReducer(history)
);

export const StoreConfig = () => {
  const preloadedState = {};
  const sagaMiddleware = createSagaMiddleware();

  const logger = createLogger({
    duration: true,
    collapsed: true,
    colors: {
      title: () => "#139BFE",
      prevState: () => "#1C5FAF",
      action: () => "#149945",
      nextState: () => "#A47104",
      error: () => "#ff0005",
    },
  });

  const middleware = [sagaMiddleware, routerMiddleware(history)];

  if (
    process.env.NODE_ENV === "development" ||
    window?.location?.host?.includes("dev")
  ) {
    middleware.push(logger);
  }

  const store = configureStore({
    reducer: persistedRootReducer,
    devTools: true,
    preloadedState,
    middleware,
  });

  sagaMiddleware.run(sagas);

  return store;
};

export const store = StoreConfig();
export const persistor = persistStore(store);
