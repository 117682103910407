import styled from "styled-components";
import { Section } from "../layout";
import { IPaths, IPath } from "../../@types";

const StyledPath = styled.div`
  justify-content: center;
  font-size: 0.9em;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const StyledPathWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--koffie-grey);
`;

const PathNav = ({ paths }: IPaths) => {
  return (
    <Section $flexDirection="row">
      {paths.map((path: IPath, idx) => {
        return (
          <StyledPathWrapper key={idx}>
            {idx !== 0 && <span style={{ margin: "0 10px" }}>{">"}</span>}
            <StyledPath key={idx} onClick={path.handleNavigateTo}>
              {path.label}
            </StyledPath>
          </StyledPathWrapper>
        );
      })}
    </Section>
  );
};

export default PathNav;
