import {
  StyledLabel,
  StyledLabelWrapper,
  StyledLabelInfoIcon,
  StyledHintSpan,
} from "./styled-form-components";
import { Section } from "../layout";
import { getMuiIcons, IconTypes } from "../../utils/@mui-icons";
import React, { useState } from "react";
import { isEmpty } from "lodash";
import { AlertRenderer } from "../alerts";

interface IFieldLabelProps {
  label: string;
  showError?: boolean;
  info?: string;
  error?: object;
  hint?: string;
  isRequired?: boolean;
  sx?: object;
}

export const FieldLabelComponent = ({
  label,
  info = "",
  hint,
  isRequired = false,
  sx = {},
}: IFieldLabelProps) => {
  const [showAlert, setShowAlert] = useState(false);
  return (
    <>
      <Section style={{ ...sx }}>
        <StyledLabelWrapper>
          <StyledLabel>
            {label}
            {isRequired && !isEmpty(label) && (
              <span style={{ color: "var(--warning-red)" }}>{" *"}</span>
            )}
          </StyledLabel>
          {!isEmpty(info) && (
            <StyledLabelInfoIcon onClick={() => setShowAlert(!showAlert)}>
              {getMuiIcons(IconTypes.info)}
            </StyledLabelInfoIcon>
          )}
        </StyledLabelWrapper>
        <StyledHintSpan>
          {hint}
          {isRequired && isEmpty(label) && (
            <span style={{ color: "var(--warning-red)" }}>{" *"}</span>
          )}
        </StyledHintSpan>
      </Section>
      {!isEmpty(info) && showAlert && (
        <Section style={{ marginTop: "8px" }}>
          <AlertRenderer
            open={showAlert}
            severity={"info"}
            message={info}
            onClose={() => setShowAlert(false)}
          />
        </Section>
      )}
    </>
  );
};
