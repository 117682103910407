import styled from "styled-components";
import { Section } from "../layout";

const LoadingDotsContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  & div {
    position: absolute;
    top: 33px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: var(--primary1);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  & div:nth-child(1) {
    left: 8px;
    animation: ellipsis1 0.6s infinite;
  }
  & div:nth-child(2) {
    left: 8px;
    animation: ellipsis2 0.6s infinite;
  }
  & div:nth-child(3) {
    left: 32px;
    animation: ellipsis2 0.6s infinite;
  }
  & div:nth-child(4) {
    left: 56px;
    animation: ellipsis3 0.6s infinite;
  }
`;

const LoadingDots = ({ message }: any) => {
  return (
    <Section $flexDirection="row" $justifyContent="center" $alignItems="center">
      <LoadingDotsContainer>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </LoadingDotsContainer>
      {message && (
        <span style={{ marginLeft: "10px", marginBottom: "4px" }}>
          {message}
        </span>
      )}
    </Section>
  );
};

export default LoadingDots;
