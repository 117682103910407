import { toast } from "react-toastify"
import { Section } from "ui-bank"

export const SuccessComponent = ({ fnol_id }: { fnol_id: string }) => {
  console.log("FNOL", fnol_id)
  return (
    <Section style={{ width: "300px", color: "black" }}>
      <span
        style={{ marginTop: "20px", textAlign: "center", fontSize: "1.4em" }}
      >
        <b>Thank you for submitting a First Notice of Loss</b>
      </span>
      <div
        style={{
          backgroundColor: "var(--light-green)",
          padding: "10px",
          maxHeight: "250px",
          overflow: "scroll",
          marginTop: "20px",
          whiteSpace: "pre-wrap",
        }}
      >
        {`REF: ${fnol_id}`}
      </div>
    </Section>
  );
};

export const renderSuccessToast = (fnol_id: string) =>
  toast.success(<SuccessComponent fnol_id={fnol_id} />, {
    position: "bottom-left",
    autoClose: 6000,
    className: "primary-success-toast",
    icon: false,
  });
