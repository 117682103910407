import { isEmpty } from "lodash";
import { Section } from "../../layout";
import { FieldLabelComponent } from "../";
import React from "react";
import { StyledInputField } from "../";
import { AlertRenderer } from "../../alerts";

export const PercentageTable = ({
  label,
  hint,
  onChange,
  listValues = [],
  currentPercentageTable,
  required = false,
  dollarTable = false,
}: any) => {
  let total = 0;
  listValues.forEach((e: any) => {
    const percentage = currentPercentageTable[e?.key];
    if (percentage) {
      total += +percentage;
    }
  });

  return (
    <Section style={{ marginBottom: "20px" }} $alignItems="flex-start">
      <FieldLabelComponent label={label} hint={hint} isRequired={required} />
      {isEmpty(listValues) ? (
        <div style={{ margin: "8px 0" }}>No Items Listed</div>
      ) : (
        <Section
          style={{
            border: "1px solid var(--primary3)",
            padding: "8px 15px",
            marginTop: "8px",
          }}
        >
          {listValues.map((item: any, idx: number) => {
            const onFieldChange = (ev: any) => {
              const fieldValue = ev?.target.value;
              onChange(item?.key, fieldValue);
            };

            return (
              <Section
                $flexDirection="row"
                key={`${item.name}${idx}`}
                $justifyContent="space-between"
                $alignItems="center"
                style={{ margin: "5px 0" }}
              >
                <span>{item?.label}</span>
                <Section
                  $flexDirection="row"
                  style={{ width: "auto" }}
                  $alignItems="center"
                >
                  {dollarTable && <div style={{ marginRight: "10px" }}>$</div>}
                  <StyledInputField
                    onChange={onFieldChange}
                    value={currentPercentageTable[item?.key]}
                    style={{ textAlign: "right" }}
                    storeVal={currentPercentageTable[item?.key]}
                    controlled={true}
                  />
                  {!dollarTable && <div style={{ marginLeft: "10px" }}>%</div>}
                </Section>
              </Section>
            );
          })}
          <Section
            $flexDirection="row"
            $justifyContent="space-between"
            $alignItems="center"
          >
            <div>
              <b>Total</b>
            </div>
            <Section
              $flexDirection="row"
              style={{ width: "auto", margin: "5px 0 15px" }}
              $alignItems="center"
            >
              {dollarTable && <div style={{ marginRight: "10px" }}>$</div>}
              <StyledInputField
                value={total}
                style={{
                  textAlign: "right",
                  fontWeight: "bold",
                  color: dollarTable
                    ? "var(--koffie-black)"
                    : total === 100
                    ? "var(--koffie-black)"
                    : "var(--warning-red)",
                  backgroundColor: dollarTable
                    ? "var(--light-info-blue)"
                    : total === 100
                    ? "var(--light-green)"
                    : "var(--light-red)",
                }}
                disabled
              />
              {!dollarTable && <div style={{ marginLeft: "10px" }}>%</div>}
            </Section>
          </Section>
          {!dollarTable && total !== 100 && (
            <Section style={{ marginBottom: "10px" }}>
              <AlertRenderer
                severity="error"
                message="Sum of percentages must be 100."
              />
            </Section>
          )}
        </Section>
      )}
    </Section>
  );
};
