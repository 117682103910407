import { css } from "styled-components";

export const animFadeIn = css`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fadeIn 800ms ease-in-out;
`;

export const animFadeOut = css`
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  animation: fadeOut 800ms ease-in-out;
`;
