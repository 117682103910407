import { isEmpty } from "lodash";
import { Section } from "../../layout";
import { FieldLabelComponent } from "../";
import React from "react";
import { StyledInputField } from "../";

export const InputList = ({ options, hint, onChange, listValues }: any) => {
  let total = 0;
  options.forEach((e: any) => {
    const percentage = listValues[e?.value];
    if (percentage) {
      total += +percentage;
    }
  });

  return (
    <Section style={{ marginBottom: "20px" }} $alignItems="flex-start">
      <FieldLabelComponent label="" hint={hint} />
      {isEmpty(options) ? (
        <div style={{ margin: "8px 0" }}>No commodities selected</div>
      ) : (
        <Section style={{ marginTop: "20px" }}>
          {options.map((opt: any, idx: number) => {
            const opt_percentage = listValues[opt.value];
            const onFieldChange = (ev: any) => {
              const fieldValue = ev?.target.value;
              onChange(opt?.value, fieldValue);
            };

            return (
              <Section
                $flexDirection="row"
                key={idx}
                $justifyContent="space-between"
                $alignItems="center"
                style={{ margin: "5px 0" }}
              >
                <span>{opt?.value}</span>
                <Section
                  $flexDirection="row"
                  style={{ width: "auto" }}
                  $alignItems="center"
                >
                  <StyledInputField
                    onChange={onFieldChange}
                    value={opt_percentage}
                    style={{ textAlign: "right" }}
                  />
                  <div style={{ marginLeft: "10px" }}>%</div>
                </Section>
              </Section>
            );
          })}
          <Section
            $flexDirection="row"
            $justifyContent="space-between"
            $alignItems="center"
          >
            <div>
              <b>Total</b>
            </div>
            <Section
              $flexDirection="row"
              style={{ width: "auto", margin: "5px 0 15px" }}
              $alignItems="center"
            >
              <StyledInputField
                value={total}
                style={{
                  textAlign: "right",
                  fontWeight: "bold",
                  color:
                    total === 100
                      ? "var(--primary-black)"
                      : "var(--warning-red)",
                  backgroundColor:
                    total === 100 ? "var(--light-green)" : "var(--light-red)",
                }}
                disabled
              />
              <div style={{ marginLeft: "10px" }}>%</div>
            </Section>
          </Section>
        </Section>
      )}
    </Section>
  );
};
