import styled from "styled-components";
import { Section, ImgBox } from "../layout";
import { StyledFormSection } from "../hook-form";
import { getMuiIcons, IconTypes } from "../../utils";

const StyledStepNumber = styled(Section)`
  border-radius: 50%;
  height: 30px;
  width: 30px;
  padding: 18px;
  background-color: ${({ $active }) =>
    $active ? "var(--primary1)" : "var(--background-darker-grey)"};
  color: ${({ $active }) => ($active ? "#fff" : "var(--primary-black)")};
  font-weight: bold;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border: 10px solid #ffffff;
  display: flex;
`;

const StyledStepLabel = styled(Section).attrs(() => ({
  active: undefined,
}))`
  font-weight: ${({ $active }) => ($active ? "bold" : "")};
  text-align: left;
  display: flex;
`;

const VerticalStepper = ({
  steps,
  currentStep,
  handleStepClick,
  showLogo = false,
  unlockedSteps = [0],
  renderToast,
  style = {},
}: any) => {
  return (
    <StyledFormSection
      style={{
        width: "35%",
        minWidth: "300px",
        padding: "30px 0 45px",
        position: "sticky",
        top: "2em",
        ...style,
      }}
      $alignItems="center"
    >
      {showLogo && (
        <ImgBox
          src="/tip-logo.jpg"
          alt="tip"
          width={200}
          style={{ marginBottom: "3em" }}
        />
      )}
      <Section $flexDirection="column" style={{ paddingLeft: "50px" }}>
        {steps &&
          steps.map((step: any) => {
            const { idx, name, stepDetails, errMsg } = step;
            const isActive = currentStep === idx;
            const stepIsUnlocked =
              unlockedSteps?.includes(idx) || unlockedSteps.includes("*");

            return (
              <Section
                key={idx}
                style={{
                  padding: "20px 0",
                  borderLeft:
                    idx !== steps.length - 1 &&
                    "1px solid var(--background-darker-grey)",
                }}
              >
                <Section
                  $alignItems="center"
                  $flexDirection="row"
                  style={{
                    left: "-28px",
                    top: idx === steps.length - 1 ? "0px" : "-25px",
                    height: idx === steps.length - 1 && "0px",
                    cursor: "pointer",
                  }}
                  onClick={
                    stepIsUnlocked
                      ? () => handleStepClick(idx)
                      : renderToast
                      ? renderToast
                      : null
                  }
                >
                  <StyledStepNumber $active={isActive}>
                    {stepIsUnlocked ? (
                      idx + 1
                    ) : (
                      <span>{getMuiIcons(IconTypes.lock)}</span>
                    )}
                  </StyledStepNumber>
                  <StyledStepLabel $active={isActive}>
                    <Section>{name}</Section>
                    <span
                      style={{
                        fontSize: "0.7em",
                        color: "var(--warning-red)",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {errMsg}
                    </span>
                    {stepDetails}
                  </StyledStepLabel>
                </Section>
              </Section>
            );
          })}
      </Section>
    </StyledFormSection>
  );
};

export default VerticalStepper;
