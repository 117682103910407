import { put, call } from "redux-saga/effects";
import baseActions from "reducers/base";
import { renderSuccessToast } from "utils/success";

export function* getFormConfig(api: any): any {
  try {
    const response = yield call(api.getFormConfig);
    if (response.ok) {
      const { data } = response;
      yield put(baseActions.getFormConfigSuccess(data));
    } else {
      const { error } = response;
      yield put(baseActions.getFormConfigFailure(error));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* saveForm(api: any, action: any): any {
  const { efnol, isSubmit } = action;

  const updatedFNOL = {
    ...efnol,
    injuries_details: efnol?.injuries_details?.map((e: any) => `${e?.name || "[Injured Person Name]"} - Phone: ${e?.phone || "N/A"}, Email: ${e?.email || "N/A"}, Address: ${e?.address || "N/A"}, Injury Description: ${e?.injury_desc || "N/A"}`)?.join("\n"),
    incident_datetime: efnol?.incident_datetime?.toString(),
    reporter_type: efnol?.reporter_type?.label,
    incident_location_state: efnol?.reporter_type?.label,
    reporter_veh_plate_state: efnol?.reporter_veh_plate_state?.label,
    reporter_dl_state: efnol?.reporter_dl_state?.label,
    additional_files: efnol?.additional_files?.join("\n"),
    official_reports: efnol?.official_reports?.map((e: any) => `${e?.name || "[Report Name]"} - Report Number: ${e?.report_number || "N/A"}, Comment: ${e?.comment || "N/A"}`)?.join("\n"),
    reporter_veh_passengers: efnol?.reporter_veh_passengers?.map((e: any) => `${e?.name || "[Passenger Name]"} - Phone: ${e?.phone || "N/A"}, Email: ${e?.email || "N/A"}, Address: ${e?.address || "N/A"}, Is Injured? ${e?.is_injured ? "Yes" : "No"}`)?.join("\n"),
    witnesses: efnol?.witnesses?.map((e: any) => `${e?.name || "[Witness Name]"} - Phone: ${e?.phone || "N/A"}, Email: ${e?.email || "N/A"}`)?.join("\n"),
    other_vehicles: efnol?.other_vehicles?.map((e: any) => `${e?.vin || "[VIN]"} - Plate State: ${e?.plate_state || "N/A"}, Plate Number: ${e?.plate_number || "N/A"}, Damage Description: ${e?.damage_description || "N/A"}, Estimated Amount: ${e?.estimated_amount || "N/A"}, Footage Available? ${e?.footage_available_bool ? "Yes" : "N/A"}, Footage Available? ${e?.footage_available_bool ? "Yes" : "N/A"}, Driver Name: ${e?.driver_name || "N/A"}, Driver DOB: ${e?.driver_dob || "N/A"}, Driver Email: ${e?.driver_email || "N/A"}, Driver Phone: ${e?.driver_phone || "N/A"}, Driver Address: ${e?.driver_address || "N/A"}, DL State: ${e?.dl_state || "N/A"}, DL Number: ${e?.dl_number || "N/A"}`)?.join("\n"),
  }

  try {
    let response;
    if (isSubmit) {
      response = yield call(api.submit, updatedFNOL);
    } else {
      response = yield call(api.saveForm, updatedFNOL);
    }
    if (response.ok) {
      const { data } = response;
      if (isSubmit) {
        yield put(baseActions.resetForm());
        yield put(baseActions.getFormConfigRequest())
        renderSuccessToast(efnol?.id)
      } else {
        yield put(baseActions.saveSuccess(data));
      }
    } else {
      const { error } = response;
      yield put(baseActions.saveFailure(error));
    }
  } catch (error) {
    console.error(error);
  }
}
