import React from "react";
import PoweredByGoogle from "../../../assets/google_on_white_hdpi.png";

import { components } from "react-select";

const menuHeaderStyle = {
  padding: "8px 12px",
};

const MenuList = (props: any) => {
  return (
    <components.MenuList {...props}>
      {props.children}
      <div style={menuHeaderStyle}>
        <img src={PoweredByGoogle} alt="powered by Google" width="60" />
      </div>
    </components.MenuList>
  );
};

export default MenuList;
