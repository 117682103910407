import {
  getReactSelectCustomStyles,
  StyledFieldWrapper,
  StyledFormWarnings,
} from "../styled-form-components";
import { FieldLabelComponent } from "../";
import Select, { components } from "react-select";
import React, { useState } from "react";
import { isEmpty } from "lodash";
import { Section } from "../../layout";
import { ErrorsHandler } from "../errors.handler";
import { KoffieDict } from "../../../utils";

const NoOptionCreateFromUserInput = ({
  noOptionsText = "No Options",
  onClick,
  ...restOfProps
}: any) => {
  return (
    <components.NoOptionsMessage {...restOfProps}>
      <span
        className="custom-css-class"
        style={{
          fontWeight: "bold",
          cursor: "pointer",
          color: "var(--koffie-primary-navy)",
        }}
        onClick={onClick}
      >
        {noOptionsText}
      </span>
    </components.NoOptionsMessage>
  );
};

export const DropdownMultiSelect = (props: any) => {
  const {
    options = [],
    label,
    hint,
    info,
    errors,
    touchedFields,
    name,
    field,
    allowUserCreatedOptions = false,
    width = "100%",
    closeMenuOnSelect = false,
    nonControlledProps = {},
    customNoOptionsText = null,
    onChange,
    required = false,
  } = props;
  const [fieldOptions, setFieldOptions] = useState(options);
  const [userInput, setUserInput] = useState("");
  const noOptionsText =
    userInput === ""
      ? customNoOptionsText
        ? customNoOptionsText
        : "Start typing to add"
      : `Click to add "${userInput}"`;
  const fieldError = errors[name];
  const fieldVal = field?.value;
  const fieldTouched =
    touchedFields[name] ||
    (!isEmpty(fieldVal) && !(fieldVal === null) && !(fieldVal === undefined));
  const showError = fieldError && fieldTouched;
  const { getFieldState, validateOnEvent } = nonControlledProps;
  const fieldState = getFieldState && getFieldState(name);
  const { invalid } = fieldState || {};

  const handleAddUserOption = () => {
    if (userInput === "") {
      return;
    }
    const { value } = field || {};
    const currentSelectedOptions = value ? value : [];
    const userOption = {
      label: userInput,
      value: userInput,
    };
    let updatedOptions: Array<{ label: string; value: string }> = [];
    let findUserOption = fieldOptions.filter(
      (op: { label: string; value: string }) => op.value === userOption.value
    );
    if (findUserOption.length === 0) {
      updatedOptions = [...fieldOptions, userOption];
      setFieldOptions(updatedOptions);
      const updatedSelectedOptions = [...currentSelectedOptions, userOption];
      onChange(updatedSelectedOptions);
      setUserInput("");
    }
  };
  const additionalComponents = allowUserCreatedOptions
    ? {
        NoOptionsMessage: (props: any) => (
          <NoOptionCreateFromUserInput
            noOptionsText={noOptionsText}
            onClick={handleAddUserOption}
            {...props}
          />
        ),
      }
    : {
        NoOptionsMessage: (props: any) => (
          <NoOptionCreateFromUserInput
            noOptionsText={customNoOptionsText || "No Options"}
            {...props}
          />
        ),
      };

  return (
    <StyledFieldWrapper width={width}>
      <FieldLabelComponent
        label={label}
        info={info}
        hint={hint}
        isRequired={required}
      />
      <Section $flexDirection="row">
        <Select
          isMulti
          onSelectResetsInput
          options={fieldOptions}
          components={{
            IndicatorSeparator: () => null,
            ...additionalComponents,
          }}
          styles={{ ...getReactSelectCustomStyles(invalid) }}
          {...field}
          {...props}
          onChange={(selected: Array<any>) => {
            if (selected?.find((e: any) => e.value === KoffieDict.select_all)) {
              const allOptions = fieldOptions?.filter(
                (e: any) =>
                  e.value !== KoffieDict.select_all &&
                  e.value !== KoffieDict.all_power_units &&
                  e.value !== KoffieDict.all_trailers
              );
              onChange(allOptions);
            } else if (
              selected?.find((e: any) => e.value === KoffieDict.all_power_units)
            ) {
              const allPowerUnits = fieldOptions?.filter((e: any) =>
                e.label.includes("Power Unit")
              );
              onChange(allPowerUnits);
            } else if (
              selected?.find((e: any) => e.value === KoffieDict.all_trailers)
            ) {
              const allTrailers = fieldOptions?.filter((e: any) =>
                e.label.includes("Trailer")
              );
              onChange(allTrailers);
            } else {
              onChange(selected);
            }
          }}
          onBlur={validateOnEvent}
          closeMenuOnSelect={closeMenuOnSelect}
          inputValue={userInput}
          onInputChange={(input) => setUserInput(input)}
        />
      </Section>
      {showError && (
        <StyledFormWarnings style={{ marginTop: "5px" }}>
          {ErrorsHandler(fieldError, label)}
        </StyledFormWarnings>
      )}
    </StyledFieldWrapper>
  );
};
