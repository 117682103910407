import {
  ListAlt,
  Insights,
  Restore,
  Add,
  Payment,
  Close,
  Twitter,
  LinkedIn,
  Instagram,
  MoreVert,
  ManageAccounts,
  NoCrash,
  HorizontalRule,
  CheckCircle,
  Delete,
  DriveFileRenameOutline,
  Upload,
  Info,
  InfoOutlined,
  MedicalServices,
  Refresh,
  MarkEmailRead,
  Drafts,
  Logout,
  AttachFile,
  Lock,
  ArrowBackIos,
  ArrowForwardIos,
  ArrowCircleUp,
  Search,
  Notifications,
  AutoFixHigh,
  Calculate,
  Settings,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  Clear,
} from "@mui/icons-material";

export const IconTypes = {
  add: "Add",
  attachFile: "AttachFile",
  arrowBackIos: "ArrowBackIos",
  arrowForwardIos: "ArrowForwardIos",
  arrowCircleUp: "ArrowCircleUp",
  calculate: "Calculate",
  checkCircle: "CheckCircle",
  clear: "Clear",
  close: "Close",
  delete: "Delete",
  doubleArrowUp: "KeyboardDoubleArrowUp",
  doubleArrowDown: "KeyboardDoubleArrowDown",
  drafts: "Drafts",
  edit: "Edit",
  horizontalRule: "HorizontalRule",
  info: "Info",
  info_outlined: "InfoOutlined",
  insights: "Insights",
  instagram: "Instagram",
  linkedin: "LinkedIn",
  listAll: "ListAll",
  lock: "Lock",
  logout: "Logout",
  magicWand: "AutoFixHigh",
  markEmailRead: "MarkEmailRead",
  medical: "MedicalServices",
  notifications: "Notifications",
  payment: "Payment",
  accountSettings: "ManageAccounts",
  refresh: "Refresh",
  restore: "Restore",
  search: "Search",
  settings: "Settings",
  twitter: "Twitter",
  upload: "Upload",
  vertDots: "MoreVert",
  vehicleCheckMark: "NoCrash",
};

export const getMuiIcons = (iconName: string, sx?: object) => {
  switch (iconName) {
    case IconTypes.add:
      return <Add fontSize="inherit" sx={sx} />;
    case IconTypes.close:
      return <Close fontSize="inherit" sx={sx} />;
    case IconTypes.info:
      return <Info fontSize="inherit" sx={sx} />;
    case IconTypes.info_outlined:
      return <InfoOutlined fontSize="inherit" sx={sx} />;
    case IconTypes.notifications:
      return <Notifications fontSize="inherit" sx={sx} />;
    case IconTypes.listAll:
      return <ListAlt fontSize="inherit" sx={sx} />;
    case IconTypes.lock:
      return <Lock fontSize="inherit" sx={sx} />;
    case IconTypes.insights:
      return <Insights fontSize="inherit" sx={sx} />;
    case IconTypes.refresh:
      return <Refresh fontSize="inherit" sx={sx} />;
    case IconTypes.restore:
      return <Restore fontSize="inherit" sx={sx} />;
    case IconTypes.twitter:
      return <Twitter fontSize="inherit" sx={sx} />;
    case IconTypes.linkedin:
      return <LinkedIn fontSize="inherit" sx={sx} />;
    case IconTypes.instagram:
      return <Instagram fontSize="inherit" sx={sx} />;
    case IconTypes.vertDots:
      return <MoreVert fontSize="inherit" sx={sx} />;
    case IconTypes.vehicleCheckMark:
      return <NoCrash fontSize="inherit" sx={sx} />;
    case IconTypes.horizontalRule:
      return <HorizontalRule fontSize="inherit" sx={sx} />;
    case IconTypes.checkCircle:
      return <CheckCircle fontSize="inherit" sx={sx} />;
    case IconTypes.clear:
      return <Clear fontSize="inherit" sx={sx} />;
    case IconTypes.delete:
      return <Delete fontSize="inherit" sx={sx} />;
    case IconTypes.edit:
      return <DriveFileRenameOutline fontSize="inherit" sx={sx} />;
    case IconTypes.upload:
      return <Upload fontSize="inherit" sx={sx} />;
    case IconTypes.markEmailRead:
      return <MarkEmailRead fontSize="inherit" sx={sx} />;
    case IconTypes.medical:
      return <MedicalServices fontSize="inherit" sx={sx} />;
    case IconTypes.accountSettings:
      return <ManageAccounts fontSize="inherit" sx={sx} />;
    case IconTypes.drafts:
      return <Drafts fontSize="inherit" sx={sx} />;
    case IconTypes.logout:
      return <Logout fontSize="inherit" sx={sx} />;
    case IconTypes.payment:
      return <Payment fontSize="inherit" sx={sx} />;
    case IconTypes.attachFile:
      return <AttachFile fontSize="inherit" sx={sx} />;
    case IconTypes.arrowBackIos:
      return <ArrowBackIos fontSize="inherit" sx={sx} />;
    case IconTypes.arrowForwardIos:
      return <ArrowForwardIos fontSize="inherit" sx={sx} />;
    case IconTypes.arrowCircleUp:
      return <ArrowCircleUp fontSize="inherit" sx={sx} />;
    case IconTypes.search:
      return <Search fontSize="inherit" sx={sx} />;
    case IconTypes.magicWand:
      return <AutoFixHigh fontSize="inherit" sx={sx} />;
    case IconTypes.calculate:
      return <Calculate fontSize="inherit" sx={sx} />;
    case IconTypes.settings:
      return <Settings fontSize="inherit" sx={sx} />;
    case IconTypes.doubleArrowUp:
      return <KeyboardDoubleArrowUp fontSize="inherit" sx={sx} />;
    case IconTypes.doubleArrowDown:
      return <KeyboardDoubleArrowDown fontSize="inherit" sx={sx} />;
    default:
      return <Add fontSize="inherit" sx={sx} />;
  }
};
