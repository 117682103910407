import {
  StyledDatePickerWrapper,
  StyledFieldWrapper,
  StyledFormWarnings,
  StyledInputField,
} from "../styled-form-components";
import DatePicker from "react-datepicker";
import React from "react";
import { isEmpty, range } from "lodash";
import { Section } from "../../layout";
import { getMuiIcons, IconTypes } from "../../../utils";
import { FieldLabelComponent } from "../";
import { getYear, getMonth } from "date-fns";
import styled, { css } from "styled-components";
import { ErrorsHandler } from "../errors.handler";

const base = css`
  border: none;
  background-color: transparent;
  color: white;
`;

const CalendarArrowBtns = styled.button`
  ${base};
  font-size: 1.2em;
`;

const calBase = css`
  width: 105px;
  ${base};
  font-weight: bold;
  font-size: 1.5em;
  padding: 0 5px;
  -moz-appearance: none;
  -webkit-appearance: none;
  :focus {
    outline: none;
  }
  ::-ms-expand {
    display: none;
  }
`;

const MonthSelect = styled.select`
  ${calBase};
  text-align: right;
`;

const YearSelect = styled.select`
  ${calBase};
  text-align: left;
`;

const CustomHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  handleFocus,
}: any) => {
  const cy = getYear(new Date());
  const years = range(cy - 100, cy + 2);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const style = {
    margin: 10,
    display: "flex",
    $justifyContent: "space-between",
  };
  return (
    <div style={style}>
      <CalendarArrowBtns
        onClick={(ev: any) => {
          decreaseMonth(ev);
          handleFocus();
        }}
        disabled={prevMonthButtonDisabled}
      >
        {getMuiIcons(IconTypes.arrowBackIos)}
      </CalendarArrowBtns>

      <Section
        $flexDirection="row"
        $alignItems="center"
        $justifyContent="center"
      >
        <MonthSelect
          value={months[getMonth(date)]}
          onChange={({ target: { value } }) =>
            changeMonth(months.indexOf(value))
          }
        >
          {months.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </MonthSelect>

        <YearSelect
          value={date.getFullYear()}
          onChange={({ target: { value } }) => changeYear(Number(value))}
        >
          {years.map((option: number) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </YearSelect>
      </Section>

      <CalendarArrowBtns
        onClick={(ev: any) => {
          increaseMonth(ev);
          handleFocus();
        }}
        disabled={nextMonthButtonDisabled}
      >
        {getMuiIcons(IconTypes.arrowForwardIos)}
      </CalendarArrowBtns>
    </div>
  );
};

export const CustomDatePicker = (props: any) => {
  const {
    label,
    name,
    info,
    errors,
    touchedFields,
    field,
    watch,
    hint,
    nonControlledProps,
    width = "100%",
    showTimeInput = false,
    wide_select = false,
    setFocus,
    onChange,
    required = false,
  } = props;
  const fieldError = errors[name];
  const fieldVal = watch(name);
  const fieldTouched =
    touchedFields[name] ||
    (!isEmpty(fieldVal) && !(fieldVal === null) && !(fieldVal === undefined));
  const showError = fieldError && fieldTouched;
  const { getFieldState } = nonControlledProps;
  const fieldState = getFieldState(name);

  const maintainFocus = () => {
    if (setFocus) {
      setFocus(name);
    }
  };

  const valIsString = typeof field?.value === "string";

  return (
    <StyledFieldWrapper ref={field?.ref} width={width}>
      <FieldLabelComponent
        label={label}
        showError={fieldError && fieldTouched}
        error={fieldError}
        info={info}
        hint={hint}
        isRequired={required}
      />
      <Section $flexDirection="row">
        <StyledDatePickerWrapper $showTimeInput={showTimeInput}>
          <DatePicker
            selected={valIsString ? new Date(field?.value) : field?.value}
            dateFormat={`MMMM do, yyyy${showTimeInput ? " - hh:mm aa" : ""}`}
            showTimeInput={showTimeInput}
            showMonthDropdown={wide_select}
            showYearDropdown={wide_select}
            renderCustomHeader={(headerProps: any) => (
              <CustomHeader {...headerProps} handleFocus={maintainFocus} />
            )}
            showDisabledMonthNavigation
            onChange={onChange}
            onBlur={field?.onBlur}
            customInput={
              <StyledInputField
                onChange={onChange}
                $isInvalid={fieldState?.invalid}
              />
            }
          />
        </StyledDatePickerWrapper>
      </Section>
      {showError && (
        <StyledFormWarnings style={{ marginTop: "5px" }}>
          {ErrorsHandler(fieldError, label)}
        </StyledFormWarnings>
      )}
    </StyledFieldWrapper>
  );
};
