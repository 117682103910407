import styled from "styled-components";
import { Section } from "../layout";

interface ISpinnerProps {
  size?: "xs" | "s" | "m" | "l";
  color?: string;
  message?: string;
}

const sizes = {
  xs: 15,
  s: 25,
  m: 50,
  l: 75,
};

const thickness = {
  xs: 2,
  s: 3,
  m: 4,
  l: 5,
};

const LoadingSpinner = styled.div`
  display: inline-block;
  position: relative;
  width: ${({ size }: ISpinnerProps) => (size ? sizes[size] : sizes.s)}px;
  height: ${({ size }: ISpinnerProps) => (size ? sizes[size] : sizes.s)}px;
  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${({ size }: ISpinnerProps) => (size ? sizes[size] : sizes.s)}px;
    height: ${({ size }: ISpinnerProps) => (size ? sizes[size] : sizes.s)}px;
    border: ${({ size }: ISpinnerProps) =>
        size ? thickness[size] : thickness.s}px
      solid var(--primary1);
    border-radius: 50%;
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ color }: ISpinnerProps) =>
        color ? color : "var(--primary1)"}
      transparent transparent transparent;
  }
  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Spinner = (props: ISpinnerProps) => {
  const { message, ...restOfProps } = props;
  return (
    <Section $flexDirection="row" $justifyContent="center" $alignItems="center">
      <LoadingSpinner className="spinner" {...restOfProps}>
        <div />
        <div />
        <div />
        <div />
      </LoadingSpinner>
      {message && <span style={{ marginLeft: "20px" }}>{message}</span>}
    </Section>
  );
};

export default Spinner;
