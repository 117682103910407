import { Section } from "../../layout";
import { FieldLabelComponent } from "../field.label";
import {
  StyledFieldWrapper,
  StyledFormWarnings,
} from "../styled-form-components";
import * as yup from "yup";
import { ErrorsHandler } from "../errors.handler";
import React from "react";
import { isEmpty } from "lodash";
import { StyledButton } from "../../buttons";
import { AlertRenderer, Modal } from "../../index";
import { FieldTypes } from "../../../@types";
import FormContentRenderer from "../content-renderer";
import { toTitleCase } from "../../../utils";

const ModalContent = (props: any) => {
  const {
    contactFields,
    contact_type,
    handleUpdateContact,
    mappedField,
    $toggleModal,
    initialValues,
    hasAddedContact,
    additionalProps,
  } = props || {};
  const valDefs = {
    name: yup.string().required(),
    address: yup.mixed().required(),
    phone: yup.string().required(),
    email: yup.string().email().required(),
    website: yup.string(),
  };

  const contactSchema = {};

  contactFields.forEach((f: string) => {
    // @ts-ignore
    return (contactSchema[f] = valDefs[f]);
  });

  const { handleGetAutocompleteOptions, handleAddressSelect, addressLocation } =
    additionalProps || {};

  return (
    <FormContentRenderer
      formFields={contactFields?.map((e: any) => {
        const autocompleteProps = e?.includes("address")
          ? {
              loadOptions: handleGetAutocompleteOptions,
              customOnChange: handleAddressSelect,
              addressLocation,
              parentName: mappedField,
              defaultValue: initialValues?.address,
            }
          : {};
        return {
          label: e.toUpperCase(),
          name: e,
          ...autocompleteProps,
          fieldType: e?.includes("phone")
            ? FieldTypes.phone
            : e?.includes("address")
            ? FieldTypes.autocomplete
            : FieldTypes.input,
        };
      })}
      formSchema={yup.object(contactSchema)}
      renderWithoutFormTags={true}
      formSubmitLabel={`${
        hasAddedContact ? "update" : "add"
      } ${contact_type} contact`}
      autosave={false}
      initialValues={initialValues}
      onFormSubmit={(data: any) => {
        const updatedContact = {
          ...data,
          contact_type,
          address: addressLocation,
        };
        handleUpdateContact(mappedField, updatedContact);
        if ($toggleModal) {
          $toggleModal();
        }
      }}
    />
  );
};

export const Contact = (props: any) => {
  const {
    label,
    hint,
    info,
    errors,
    touchedFields,
    name,
    field,
    width = "100%",
    nonControlledProps = {},
    // onChange,
    contactFields,
    contact_type,
    handleUpdateContact,
    required = false,
    initialValue,
    additionalProps,
  } = props;
  const fieldError = errors[name];
  const fieldVal = field?.value;
  const fieldTouched =
    touchedFields[name] ||
    (!isEmpty(fieldVal) && !(fieldVal === null) && !(fieldVal === undefined));
  const showError = fieldError && fieldTouched;
  const { getFieldState, setValue, watch } = nonControlledProps;
  const fieldState = getFieldState && getFieldState(name);
  const { invalid } = fieldState || {};
  const addedContacts = initialValue?.filter(
    (e: any) => e?.contact_type === contact_type
  );

  const hasAddedContact = !isEmpty(addedContacts);
  const insuredContact = addedContacts?.find(
    (c: any) => c?.contact_type === "insured"
  );

  return (
    <StyledFieldWrapper width={width}>
      <FieldLabelComponent
        label={label}
        info={info}
        hint={hint}
        isRequired={required}
      />
      {isEmpty(insuredContact) && contact_type === "insured" && (
        <div style={{ marginBottom: "15px" }}>
          <AlertRenderer
            severity="error"
            message="A primary contact is required."
          />
        </div>
      )}
      {hasAddedContact &&
        addedContacts.map((e: any, idx: number) => {
          const keys = Object.keys(e);

          return (
            <Section
              key={idx}
              style={{
                backgroundColor: "var(--background-grey)",
                padding: "10px 15px",
                marginRight: "15px",
                marginBottom: "15px",
              }}
            >
              {keys?.map((k: string, idx: number) => {
                const val = e?.[k];
                const isValidKey =
                  contactFields.includes(k) || k === "contact_type";
                return !isValidKey ? (
                  <></>
                ) : (
                  <Section key={idx} $flexDirection="row">
                    <span style={{ fontWeight: "bold" }}>
                      {toTitleCase(`${k?.replaceAll("_", " ")}:`)}
                    </span>
                    {k === "address" && !isEmpty(k) ? (
                      <span style={{ marginLeft: "10px" }}>
                        {val?.line_1 +
                          ", " +
                          val?.city +
                          ", " +
                          val?.state +
                          val?.zip}
                      </span>
                    ) : (
                      <span key={idx} style={{ marginLeft: "10px" }}>
                        {k === "contact_type" ? val?.toUpperCase() : val}
                      </span>
                    )}
                  </Section>
                );
              })}
            </Section>
          );
        })}
      <Section $flexDirection="row">
        <Modal
          title={label}
          Trigger={({ onClick }: any) => {
            return (
              <StyledButton
                label={`${hasAddedContact ? "Edit" : "Add"} Contact`}
                btn_type="primary"
                onClick={onClick}
                // isDisabled={hasAddedContact}
              />
            );
          }}
          size="sm"
        >
          <ModalContent
            contactFields={contactFields}
            contact_type={contact_type}
            mappedField={name}
            handleUpdateContact={handleUpdateContact}
            hasAddedContact={hasAddedContact}
            initialValues={hasAddedContact ? addedContacts[0] : {}}
            setValue={setValue}
            watch={watch}
            additionalProps={additionalProps}
          />
        </Modal>
      </Section>
      {showError && (
        <StyledFormWarnings style={{ marginTop: "5px" }}>
          {ErrorsHandler(fieldError, label)}
        </StyledFormWarnings>
      )}
    </StyledFieldWrapper>
  );
};
