import styled, { css } from "styled-components";
import { LoadingSpinner } from "../loading-spinner";

type ButtonTypes = "primary" | "secondary" | "alternative";

const btnBaseStyles = css`
  display: inline-flex;
  height: 50px;
  width: 160px;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 0.8em;
  letter-spacing: 1.5px;
  align-items: center;
  justify-content: center;
  border: none;
`;

const btnTypeStyles = css`
  color: #ffffff;
  font-weight: bold;
  &:hover {
    background-color: var(--primary2);
  }
  transition: background-color 300ms;
`;

const StyledButtonComponent: any = styled.button<any>`
  background-color: ${({ $btn_type }: { $btn_type: ButtonTypes }) =>
    `var(--${$btn_type === "primary" ? "primary1" : "primary3"})`};
  opacity: ${({ disabled }: any) => (disabled ? 0.6 : 1)};
  cursor: ${({ disabled }: any) => (disabled ? "not-allowed" : "pointer")};
  ${btnBaseStyles}
  ${btnTypeStyles}
`;

const StyledLoadingWrapper: any = styled.button`
  display: inline-flex;
  height: 50px;
  width: 160px;
  border: 2px solid var(--background-grey);
  background-color: var(--background-grey);
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 0;
  cursor: not-allowed;
`;

interface IButtonProps {
  id?: string;
  label: any;
  /*
   * onClick needs to stay optional because
   * for buttons in forms, the onClick f(x)
   * is handled at form submit level
   * */
  onClick?: (data?: any) => void;
  btn_type: ButtonTypes;
  style?: object;
  type?: string;
  loading?: boolean;
  isDisabled?: boolean;
}

export const StyledButton = ({
  label,
  onClick,
  btn_type = "primary",
  loading,
  isDisabled,
  style = {},
}: IButtonProps) => {
  return !loading ? (
    <StyledButtonComponent
      onClick={onClick}
      $btn_type={btn_type}
      // isDisabled={isDisabled}
      disabled={isDisabled}
      style={style}
    >
      {label}
    </StyledButtonComponent>
  ) : (
    <StyledLoadingWrapper disabled>
      <LoadingSpinner size="xs" color="#000000" />
    </StyledLoadingWrapper>
  );
};
