import { useState } from "react";
import styled, { css } from "styled-components";
import { Section } from "../layout";
import { ITabs, ITab } from "../../@types";

const BaseStyle = css`
  width: 100%;
  height: 40px;
  justify-content: center;
  font-size: 1.1em;
  padding-bottom: 20px;
  text-align: center;
`;

const StyledTab = styled(Section)`
  ${BaseStyle}
  border-bottom: 1px solid var(--background-darker-grey);
  cursor: pointer;
`;

const StyledActiveTab = styled(Section)`
  ${BaseStyle}
  font-weight: bold;
  border-bottom: 3px solid black;
`;

const Tabs = ({
  tabs,
  activeTab = 0,
  callback,
  styleTab,
  style,
  styleActiveTab,
  useActiveTab,
}: ITabs) => {
  const [currentTab, setCurrentTab] = useState(activeTab);
  const handleTabClick = (key: number) => {
    setCurrentTab(key);
    if (callback) {
      callback(key);
    }
  };

  return (
    <Section
      $flexDirection="row"
      style={{ width: "100%", marginBottom: "2em", ...style }}
      $justifyContent="space-around"
    >
      {tabs?.map((tab: ITab) => {
        const isActive = (useActiveTab ? activeTab : currentTab) === tab.key;
        return isActive ? (
          <StyledActiveTab key={tab.key} style={styleActiveTab}>
            {tab.label}
          </StyledActiveTab>
        ) : (
          <StyledTab
            key={tab.key}
            style={styleTab}
            onClick={() => handleTabClick(tab.key)}
          >
            {tab.label}
          </StyledTab>
        );
      })}
    </Section>
  );
};

export default Tabs;
