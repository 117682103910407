import { takeLatest } from "@redux-saga/core/effects";
import api from "api";

import { BaseTypes as Types } from "reducers/base";
import * as Sagas from "./base-sagas";

const listeners: any = [
  takeLatest(Types.GET_FORM_CONFIG_REQUEST, Sagas.getFormConfig, api),
  takeLatest(Types.SAVE_REQUEST, Sagas.saveForm, api),
];

export default listeners;
