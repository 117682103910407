import {
  FullLayout,
  LoadingDots,
  SectionLayout,
  Section,
  FormContentRenderer,
  StyledButton,
} from "ui-bank";

import RootLayout from "./layout";
import baseActions from "reducers/base";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "reducers/config/hooks";

import Logo from "assets/USadminlogo2022.svg";

import api from "api/api";

export default function Home() {
  const dispatch = useAppDispatch();

  const config = useAppSelector((state: any) => state?.base?.config);
  const { loading, data } = config || {};

  useEffect(() => {
    dispatch(baseActions.getFormConfigRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formData = useAppSelector((state: any) => state?.base?.form?.data) || {};
  const formLoading = useAppSelector(
    (state: any) => state?.base?.form?.loading
  );
  const sections = data?.tabs || [];

  const handleFileUploadSuccess = (storageURL: string, mappedField: string) => {
    dispatch(baseActions.uploadFileSuccessCallback(storageURL, mappedField));
  };

  const handleFileRemove = (storageURL: string, mappedField: string) => {
    dispatch(baseActions.removeFile(storageURL, mappedField));
  };

  const handleSaveFormData = (data: any) => {
    dispatch(baseActions.updateFormData(data));
  };

  const handleUpdateEntity = (data: any, mappedField: string) => {
    const entityKeys = Object.keys(data || {}) || []
    const updatedEntity: any = {}
    entityKeys?.forEach((k: string) => {
      const val = data?.[k]
      const isOption = typeof val === "object" && Object.keys(val || {})?.includes("value")
      return updatedEntity[k] = isOption ? val?.value : val
    })
    dispatch(baseActions.updateEntity(updatedEntity, mappedField));
  };

  const handleSubmitForm = () => {
    dispatch(baseActions.saveRequest(formData, true));
  };

  const dropzoneProps = {
    uploadCall: (uploadFormData: any) => {
      uploadFormData.append("fnol_id", formData?.id)
      return api.post(`upload`, uploadFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    },
    successCallback: handleFileUploadSuccess,
    handleFileRemove,
    formData,
  };

  return (
    <RootLayout>
      <FullLayout>
        <SectionLayout
          style={{ maxWidth: window.innerWidth > 400 ? "95%" : "88%" }}
        >
          <Section $alignItems="center" $justifyContent="center">
            <img
              src={Logo}
              alt="us-admin-claims-logo"
              height={125}
              style={{ marginTop: window.innerWidth < 500 ? "2em" : 0 }}
            />
            <span
              style={{
                fontSize: "2.8em",
                fontWeight: "bold",
                marginTop: "1.5em",
                marginBottom: "0.2em",
                textAlign: "center",
              }}
            >
              FIRST NOTICE OF LOSS
            </span>
            <span style={{ marginBottom: "1em", textAlign: "center" }}>
              Please complete and submit this form to report an incident.
            </span>
          </Section>
          {loading ? (
            <LoadingDots />
          ) : (
            <Section>
              {sections?.map((s: any, idx: number) => {
                return (
                  <Section key={s?.key || idx}>
                    <h1>{s?.label?.toUpperCase()}</h1>
                    <FormContentRenderer
                      renderWithoutFormTags={true}
                      hideFormSubmit={true}
                      formFields={s?.questions}
                      dropzoneProps={dropzoneProps}
                      autosave={true}
                      onFormSubmit={handleSaveFormData}
                      handleUpdateEntity={handleUpdateEntity}
                      initialValues={
                        { ...data?.initialValues, ...formData } || {}
                      }
                      formData={formData}
                    />
                  </Section>
                );
              })}
              <Section $justifyContent="center" $alignItems="center">
                <StyledButton
                  label="Submit"
                  btn_type="primary"
                  style={{ marginTop: "1em" }}
                  onClick={handleSubmitForm}
                  loading={formLoading}
                />
              </Section>
            </Section>
          )}
        </SectionLayout>
      </FullLayout>
    </RootLayout>
  );
}
